const relationData = [
  {
    title: '並列類',
    text: '並列類',
    value: '並列類',
    children: [
      {
        title: '並列複句',
        text: '並列',
        value: '並列複句',
        children: [
          {
            title: '並列',
            text: '並列',
            value: '並列',
            example:
              '李老師聽過之後會即時指正，並講授文本背後蘊含的各種思想脈絡和意義。',
            keyword:
              'H：也、亦、以及、並、且、並且、而且、又、則、或……\nQH：也~也、一~二~三……',
          },
          {
            title: '累加',
            text: '累加',
            value: '累加',
            example: '這既是我國司法的劃時代變革，也是一項嶄新的制度挑戰。',
            keyword:
              'H：也、加上、還、又、亦、更、此外、再加上……\nQH：既~又/也/且/亦、又~又……',
          },
          {
            title: '伴隨',
            text: '伴隨',
            value: '伴隨',
            example: '一邊拍著，一邊坐到石供桌上。',
            keyword: 'QH：一邊~一邊、一面~一面……',
          },
          {
            title: '交替',
            text: '交替',
            value: '交替',
            example: '他的心臟一會兒好像不跳了，一會兒又跳得很急。',
            keyword: 'QH：有時~有時、一會兒~一會兒……',
          },
        ],
      },
      {
        title: '選擇複句',
        text: '選擇複句',
        value: '選擇複句',
        children: [
          {
            title: '選擇',
            text: '選擇',
            value: '選擇',
            example: '在自然中是無美醜可言的，或竟是醜的。',
            keyword:
              'H：還是、或、或者、抑或是……\nQH：是~還是、不是~就是、若非~便是、要麼~要麼、或者~或者……',
          },
        ],
      },
      {
        title: '遞進複句',
        text: '遞進複句',
        value: '遞進複句',
        children: [
          {
            title: '遞進',
            text: '遞進',
            value: '遞進',
            example: '再這麼下去不但不可能有出路，恐怕連人生都要賠上了。',
            keyword:
              'H：更、甚至、也、還、而且、進而、連、況且、乃至於\nQH：不但/不僅~還/而且/甚至/更/也、尚且/猶~何況/更別提……',
          },
          {
            title: '反逼',
            text: '反逼',
            value: '反逼',
            example: '',
            keyword: '',
          },
        ],
      },
    ],
  },
  {
    title: '承接類',
    text: '承接類',
    value: '承接類',
    children: [
      {
        title: '承接複句',
        text: '承接複句',
        value: '承接複句',
        children: [
          {
            title: '承接',
            text: '承接',
            value: '承接',
            example: '一陣寒瀨瀉過，秋意便瀰漫日式的庭院了。',
            keyword: 'Q：一、先……\nH：後來、然後、就、再、之後、終於、接著……',
          },
          {
            title: '事承',
            text: '事承',
            value: '事承',
            example:
              '不過精準醫學的基礎應該基因體學，而基因體學旳基礎則是遺傳學。',
            keyword: 'H：而、則……',
          },
          {
            title: '持續',
            text: '持續',
            value: '持續',
            example: '它們在我的腦中埋下了這個思路，直到今天。',
            keyword: 'H：直到、仍、還、依舊……',
          },
        ],
      },
      {
        title: '時間複句',
        text: '時間複句',
        value: '時間複句',
        children: [
          {
            title: '時間',
            text: '時間',
            value: '時間',
            example: '當亦恩離開人世，我的生命也變了。',
            keyword:
              'Q：X、Y、X~Y\nX：在、於、當、每當、到(了)、直到、等、等到、待到、迄、每逢、趁著、從、自、自從、自從、繼……\nY：~時/的時候/之際/中/裡/之中/當中/之間/間/為止/前/之前/後/之後/以後/過後/起/開始/來/以來……\nQH：從/由~到、由~而、自~至……\n H：終於、才、就、這時、也、當時……',
          },
        ],
      },
    ],
  },
  {
    title: '解說類',
    text: '解說類',
    value: '解說類',
    children: [
      {
        title: '解說複句',
        text: '解說複句',
        value: '解說複句',
        children: [
          {
            title: '解說',
            text: '解說',
            value: '解說',
            example:
              '「損失與損害基金」與「氣候賠償」不同，「賠償」並不適用於當前氣候討論中。',
            keyword:
              'H：就是、即、尤其、尤其是、特別是、特別、包括、包含、其中……',
          },
          {
            title: '改說',
            text: '改說',
            value: '改說',
            example:
              '這是不容許程度等級的差別摻入自然裡去；即不容許有價值問題。',
            keyword: 'H：也就是、即、換言之、也就是說、亦即、換句話說……',
          },
          {
            title: '例證',
            text: '例證',
            value: '例證',
            example: '人們很難懷念外人的東西，比如懷念鄰家的鐘錶或者大衣櫃。',
            keyword: 'H：例如、像、像是、如、譬如、比如、比方說……',
          },
          {
            title: '注解',
            text: '注解',
            value: '注解',
            example:
              '成功的模仿出類似昆蟲複眼結構的光學鏡片，如圖2的電子顯微鏡照片。',
            keyword: '（通常不帶關聯詞）',
          },
          {
            title: '申論',
            text: '申論',
            value: '申論',
            example: '一切作為都有未知的一面。事物是否長久，在於能否接納未知。',
            keyword: '（通常不帶關聯詞）',
          },
          {
            title: '異同',
            text: '異同',
            value: '異同',
            example: '其靜寂如同我此時用毛筆寫在中紙上一樣，一點的聲息也沒有。',
            keyword: 'Q：像/和/跟/與~一樣、不比、就像……',
          },
        ],
      },
      {
        title: '評論複句',
        text: '評論複句',
        value: '評論複句',
        children: [
          {
            title: '評論',
            text: '評論',
            value: '評論',
            example:
              '三板橋渾然天成的古樸裡還流露著原始的森林氣息，實為古橋裡的一奇。',
            keyword: '（通常不帶關聯詞）',
          },
          {
            title: '主題',
            text: '主題',
            value: '主題',
            example: '能否繼續創造繁榮或步向毀滅，全看人類自己。',
            keyword: 'Q：在~方面、在~上、至於、關於、就~而論、所謂……',
          },
          {
            title: '背景',
            text: '背景',
            value: '背景',
            example: '我家後院有種樹，看到樹長出很多果實是非常高興的。',
            keyword: 'Q：在~下、在~中……',
          },
          {
            title: '比擬',
            text: '比擬',
            value: '比擬',
            example: '清清楚楚的窩成一團，貓似的。',
            keyword: 'H：像/好像/如/彷彿/宛如~似的、跟~相似/一樣……',
          },
          {
            title: '比較',
            text: '比較',
            value: '比較',
            example: '相較於東安橋的拘謹，北港橋充滿宏偉的霸氣。',
            keyword: 'Q：比、比起、與/和/跟~相比、相較於……\n H：相較之下',
          },
        ],
      },
      {
        title: '總分複句',
        text: '總分複句',
        value: '總分複句',
        children: [
          {
            title: '總分',
            text: '總分',
            value: '總分',
            example:
              '減輕壓力的方法有兩種，一是定時運動，二是定時和心理諮詢約談。',
            keyword: '（通常不帶關聯詞）',
          },
          {
            title: '分總',
            text: '分總',
            value: '分總',
            example: '圖畫的鮮明或暗淡，或一明一暗，都憑所要激起的情感而決定。',
            keyword: '（通常不帶關聯詞）',
          },
        ],
      },
      {
        title: '總結複句',
        text: '總結複句',
        value: '總結複句',
        children: [
          {
            title: '總結',
            text: '總結',
            value: '總結',
            example: '此后似乎就不相見。總之，在我的記憶上，那一次就是永別了。',
            keyword: 'H：總之、總言之、綜上……',
          },
        ],
      },
    ],
  },
  {
    title: '因果類',
    text: '因果類',
    value: '因果類',
    children: [
      {
        title: '因果複句',
        text: '因果複句',
        value: '因果複句',
        children: [
          {
            title: '因果',
            text: '因果',
            value: '因果',
            example: '因為內容艱深，很難吸引大批年輕學子。',
            keyword:
              'Q：因為、由於……\nH：所以、因此、因而、於是、結果、是故、就、便、即、則…….',
          },
          {
            title: '推論',
            text: '推論',
            value: '推論',
            example: '牆既然是人們打造的，當然也就可以由人們拆除、重建。',
            keyword:
              'Q：既然……\nH：那麼、那、就、便、則、可見、看來、看樣子、想來、由此可見、如此看來……',
          },
          {
            title: '致使',
            text: '致使',
            value: '致使',
            example: '熱情不減，讓人感動。',
            keyword: 'H：以致、以致於、使得、使、令、讓、教/叫……',
          },
          {
            title: '釋因',
            text: '釋因',
            value: '釋因',
            example: '爸媽原本抗拒搬來新厝，因為不想離開熟悉的環境及鄰居。',
            keyword: 'Q：之所以、所以\nH：因為、是因為、係因、乃因、緣於……',
          },
        ],
      },

      {
        title: '目的複句',
        text: '目的複句',
        value: '目的複句',
        children: [
          {
            title: '為目',
            text: '為目',
            value: '為目',
            example: '為了陪伴女兒成長，她決心接受治療。',
            keyword:
              'Q：為了、為、為~起見、為~之故、為免\nQ後置：為的是、是為了、就是為了……',
          },
          {
            title: '以目',
            text: '以目',
            value: '以目',
            example: '充分合作交流技術，以解決如病蟲害防治及灌溉等方面之問題。',
            keyword:
              'H：以求、以便、以利、以期、藉以、藉此、好、以免、以防、免得、省得……',
          },
        ],
      },
    ],
  },
  {
    title: '條件類',
    text: '條件類',
    value: '條件類',
    children: [
      {
        title: '假設複句',
        text: '假設複句',
        value: '假設複句',
        children: [
          {
            title: '假設',
            text: '假設',
            value: '假設',
            example: '如果能把波蘭搬到海裡，那該多好！ ',
            keyword:
              'Q：如果、假設、假如、假使、假若、若、若是、倘、倘若、倘使、要是、要、萬一、~的話、要不是、若不是、若非……\nH：就、便、即、則、才、乃、方、那、那麼……',
          },
          {
            title: '假轉',
            text: '假轉',
            value: '假轉',
            example: '「要壓一些石頭上去，否則野狗會把屍體重新挖出來。」',
            keyword: 'H：否則、不然、要不、要不然……\nQH：除非~否則/不然/要不然',
          },
        ],
      },
      {
        title: '條件複句',
        text: '條件複句',
        value: '條件複句',
        children: [
          {
            title: '條件',
            text: '條件',
            value: '條件',
            example: '只要大鳥在籠子裡生氣地叫一聲，它立即飛回籠裡去。',
            keyword:
              'Q：只要、一旦、凡是、舉凡、大凡、只有、祇有、唯有、唯獨……\nH：就、便、即、則、才、乃、方、始、那麼、那……\nQH：除非~才……',
          },
          {
            title: '排除',
            text: '排除',
            value: '排除',
            example: '除了自己反省外，則無藥可治。',
            keyword: 'Q：除了(~之外)、除、除去、除開、在~之餘/之外……',
          },
          // {
          //   title: "連鎖",
          //   text: "連鎖",
          //   value: "連鎖",
          // },
          {
            title: '倚變',
            text: '倚變',
            value: '倚變',
            example: '社會學學得越多，越感到無力。',
            keyword: 'QH：WH~WH(就/也)、越/愈~(就/也)越/愈…….',
          },
        ],
      },
    ],
  },
  {
    title: '讓轉類',
    text: '讓轉類',
    value: '讓轉類',
    children: [
      {
        title: '讓步複句',
        text: '讓步複句',
        value: '讓步複句',
        children: [
          {
            title: '實讓',
            text: '實讓',
            value: '實讓',
            example: '雖然不是親屬，別後有時也要想念到他。',
            keyword:
              'Q：雖然、雖、雖然說、雖說、儘管、固然、固、明明、誠然……\nH：轉折關聯詞',
          },
          {
            title: '虛讓',
            text: '虛讓',
            value: '虛讓',
            example: '即使出門了，也經常只是一個人散了一段很長很長的步。',
            keyword:
              'Q：即使、就算、就是、哪怕、縱使、即便、即、便、縱、任憑、再……\nH：也、還、還是、仍、仍然、仍是…….',
          },
          {
            title: '總讓',
            text: '總讓',
            value: '總讓',
            example: '學生不論在國內發展或是國際競爭上，都會有良好的基礎。',
            keyword:
              'Q：無論、不論、不管、任憑、隨、再……\nH：都、皆、均、總、總是……',
          },
          {
            title: '忍讓',
            text: '忍讓',
            value: '忍讓',
            example: '寧可讓商紂方命虐民，而不肯加以誅戮。',
            keyword: 'QH：寧可/寧願/寧~也不',
          },
        ],
      },

      {
        title: '轉折複句',
        text: '轉折複句',
        value: '轉折複句',
        children: [
          {
            title: '轉折',
            text: '轉折',
            value: '轉折',
            example: '我感到傻，但不感到錯。',
            keyword:
              'H：但是、但、可是、可、不過、只是、只、只不過、卻、卻是、倒、然、然而、然則、而、也、仍、仍然、還是、還、又、而又、而亦、竟、竟然、居然、沒想到、想不到、誰想、不想、豈料、不料、不期、殊不知……',
          },
          {
            title: '對比',
            text: '對比',
            value: '對比',
            example: '那天下也許是趙家的天下，那山水却是米家的山水。',
            keyword: 'QH：是~(而)不是、不是/並非~而是、一方面~另一方面……',
          },
          {
            title: '取捨',
            text: '取捨',
            value: '取捨',
            example: '風格與其說是文字的特異，還不如說是思想的力量。',
            keyword: 'QH：與其~不如/還不如/倒不如/反不如/寧可/寧願……',
          },
          {
            title: '遞轉',
            text: '遞轉',
            value: '遞轉',
            example: '可是我們並不受多少感動，而有時反覺得可笑可厭。',
            keyword:
              'H：反而、反、倒是、反倒、反倒是、反是、反之、顛倒、相反地、反過來……',
          },
        ],
      },
    ],
  },
  {
    title: '單句類',
    text: '單句類',
    value: '單句類',
    children: [
      {
        title: '句法',
        text: '句法',
        value: '句法',
        children: [
          {
            title: '',
            text: '',
            value: '',
            example: '',
            keyword: '',
          },
          {
            title: '主謂',
            text: '主謂',
            value: '主謂',
            example: '現實的苦惱，消盡了我們的幽默感。',
            keyword: '（通常不帶關聯詞）',
          },
          {
            title: '述賓',
            text: '述賓',
            value: '述賓',
            example: '本報調查顯示，能源弱勢遍及各地。',
            keyword: '（通常不帶關聯詞）',
          },
          {
            title: '兼語',
            text: '兼語',
            value: '兼語',
            example: '我已經指揮國安團隊和政府各部門，展開各項的因應作為。',
            keyword: '（通常不帶關聯詞）',
          },
          {
            title: '狀中',
            text: '狀中',
            value: '狀中',
            example: '的確，明天才是我們奮鬥的戰場。',
            keyword: '（通常不帶關聯詞）',
          },
          {
            title: '定中',
            text: '定中',
            value: '定中',
            example: '',
            keyword: '（通常不帶關聯詞）',
          },
          {
            title: '句內',
            text: '句內',
            value: '句內',
            example: '很榮幸能在此，接受各位的託付。',
            keyword: '（通常不帶關聯詞）',
          },
        ],
      },
      {
        title: '語義',
        text: '語義',
        value: '語義',
        children: [
          {
            title: '呼告',
            text: '呼告',
            value: '呼告',
            example: '小三的媽！把兩個大的送到王大爺屋裡去！',
            keyword: '（通常不帶關聯詞）',
          },
          {
            title: '引述',
            text: '引述',
            value: '引述',
            example: '王宜忠說：「這些是來看你的朋友！」',
            keyword: 'Q：如~所言、如~所說……',
          },
          {
            title: '連繫',
            text: '連繫',
            value: '連繫',
            example: '不過，其他氣候議題進展乏善可陳。',
            keyword: '（通常不帶關聯詞）',
          },
          {
            title: '憑藉',
            text: '憑藉',
            value: '憑藉',
            example: '透過講者趣味的詮釋，瞭解古人在字裡行間的意涵。',
            keyword:
              'Q：以、用、由、從、依、靠、依賴、憑、據、藉由、藉著、憑藉、根據、本著、經、經由……',
          },
          {
            title: '處置',
            text: '處置',
            value: '處置',
            example: '攜手將臺灣的學術能量，展現於讀者面前。',
            keyword: 'Q：把、將',
          },

          {
            title: '地方',
            text: '地方',
            value: '地方',
            example: '在這都市裡，這一條散步路如今更顯得古雅幽靜。',
            keyword:
              'Q：(在/從)~裡/(之)中/(之)內/(之)上/(之)下/(之)前/(之)後/(之)間/之處、從、在、從~到……',
          },

          {
            title: '立場',
            text: '立場',
            value: '立場',
            example: '對小國而言，比較保險的還是盡量參與國際組織。',
            keyword:
              'Q：(就/對)~而言/來說/而論/來看、一般來說、之於、在~眼中……',
          },
          {
            title: '對象',
            text: '對象',
            value: '對象',
            example: '對於曾經影響過我的作家的作品，近幾年我也很少再看。',
            keyword: 'Q：對、就、對於、針對…..',
          },
          {
            title: '來源',
            text: '來源',
            value: '來源',
            example: '從大自然，我們應分取得我們繼續的滋養。',
            keyword: 'Q：從~(中/裡)……',
          },
          {
            title: '方向',
            text: '方向',
            value: '方向',
            example: '對著一面長方形的小鏡子，修補她的嘴唇。',
            keyword: 'Q：對、對著、朝、朝著……',
          },
          {
            title: '範圍',
            text: '範圍',
            value: '範圍',
            example: '在中國的古典中，許由洗耳的例子是極限。',
            keyword: 'Q：從/在~(中/裡/上)、從~到、~之中……',
          },
          {
            title: '受益',
            text: '受益',
            value: '受益',
            example: '為產業的國際合作，創造更有利的條件。',
            keyword: 'Q：為、替……',
          },

          {
            title: '伴者',
            text: '伴者',
            value: '伴者',
            example: '和美、日、歐等共享價值的國家，深化夥伴關係。',
            keyword: 'Q：和、與、跟、同……',
          },
        ],
      },
    ],
  },
];
// const flattenData = (tree) => {
//   const result = [];
//   tree.forEach((item) => {
//     result.push({
//       title: item.text,
//       value: item.value,
//       key: item.value,
//     });
//     if (item.children) {
//       result.push(...flattenData(item.children));
//     }
//   });
//   return result;
// };
const filterTree = (nodes) => {
  let filteredTree = []; // 存儲不包含「句法」的樹
  let syntaxNodes = []; // 存儲「句法」及其子節點
  let allLeaves = []; // 存儲不包含「句法」的所有葉子節點

  const traverse = (node) => {
    if (node.title === '句法') {
      // syntaxNodes.push(node); // 將「句法」節點及其子節點加入 syntaxNodes
      if (node.children) {
        // 收集所有子節點
        const collectSyntaxChildren = (children) => {
          children.forEach((child) => {
            syntaxNodes.push(child);
            if (child.children) {
              collectSyntaxChildren(child.children); // 遞迴收集所有子節點
            }
          });
        };
        collectSyntaxChildren(node.children);
      }
      return null; // 不將「句法」節點加入到過濾後的樹
    }

    let newNode = { ...node }; // 創建當前節點的副本
    if (node.children) {
      newNode.children = node.children.map(traverse).filter(Boolean); // 過濾掉為 null 的節點
      if (newNode.children.length === 0) {
        delete newNode.children; // 如果沒有子節點，刪除 children 屬性
      }
    }

    if (!newNode.children) {
      allLeaves.push(newNode); // 如果是葉子節點，加入到 allLeaves 列表
    }

    return newNode; // 返回過濾後的節點
  };

  filteredTree = nodes.map(traverse).filter(Boolean); // 過濾掉為 null 的頂層節點

  return { filteredTree, syntaxNodes, allLeaves };
};

// 假設你的樹狀數據是 relationData
const { filteredTree, syntaxNodes, allLeaves } = filterTree(relationData);
const relationList = allLeaves;
const syntaxList = syntaxNodes;

const relationTree = filteredTree;
// console.log(relationTree);

// const relationTree = relationData;
const classList = [
  {
    title: '散文(SW)',
    text: '散文',
    value: 'SW',
  },
  {
    title: '報導(BD)',
    text: '報導',
    value: 'BD',
  },
  {
    title: '演講(YJ)',
    text: '演講',
    value: 'YJ',
  },
  {
    title: '小說(XS)',
    text: '小說',
    value: 'XS',
  },
  {
    title: '研究(RS)',
    text: '研究',
    value: 'RS',
  },

  {
    title: '議論(YL)',
    text: '議論',
    value: 'YL',
  },
  {
    title: '專欄(ZL)',
    text: '專欄',
    value: 'ZL',
  },
];
const typeList = [
  {
    text: '散文',
    value: '散文',
  },
  {
    text: '報導',
    value: '報導',
  },

  {
    text: '演講',
    value: '演講',
  },
  {
    text: '小說',
    value: '小說',
  },
  {
    text: '未填',
    value: '未填',
  },
];
const styleList = [
  {
    text: '說明',
    value: '說明',
  },

  {
    text: '描寫',
    value: '描寫',
  },

  {
    text: '記敘',
    value: '記敘',
  },
  {
    text: '論說',
    value: '論說',
  },
  {
    text: '綜合',
    value: '綜合',
  },
  {
    text: '未填',
    value: '未填',
  },
];
const copyrightList = [
  {
    text: '已授權',
    value: '已授權',
  },
  {
    text: '未授權',
    value: '未授權',
  },
];
// const syntaxList = [
//   {
//     text: '',
//     value: '',
//   },
//   {
//     text: '主謂',
//     value: '主謂',
//   },
//   {
//     text: '述賓',
//     value: '述賓',
//   },
//   {
//     text: '兼語',
//     value: '兼語',
//   },
//   {
//     text: '連繫',
//     value: '連繫',
//   },
//   {
//     text: '狀中',
//     value: '狀中',
//   },
//   {
//     text: '定中',
//     value: '定中',
//   },
//   {
//     text: '句內',
//     value: '句內',
//   },
// ];
const levelList = [
  {
    title: '複句(F)',
    text: 'F',
    value: 'F',
    example:
      '複句，由分句構成的複句關係，例如：「正因為有他們當時的努力，我們這一個世代才得以安穩的從事臺灣史研究。」',
  },
  {
    title: '句群(J)',
    text: 'J',
    value: 'J',
    example:
      '句組，由複句與複句構成的結構關係，例如：「在一本有關音樂審美的著述中涵蓋此項工作，實為極大野心；稍一不慎可能使討論繁雜而失焦。不過，若要呈現身心審美在科學發達的今日之時代意義，那麼相關的實證科學與經驗學科之發現便不容忽略，也不能不處理〈樂記〉主張與這些相關學科發現之間的關係。」此段陳述由「不過」連接，前後句段皆為。由於句組段落往往很長，為保護原著智慧財產，本語料庫將不展示句組方面的資料。',
  },
  {
    title: '單句(D)',
    text: 'D',
    value: 'D',
    example:
      '單句，標點符號區隔的是單句內成分，例如：「李永平的文學成就，相當耀眼。」',
  },
  {
    title: '單句內複句(DF)',
    text: 'DF',
    value: 'DF',
    example:
      '單句中的，標點符號區隔的是包接在單句中的，並以括弧標記範圍，例如：「萬不會想到我正流連着這[葉尚未凋，草已添黃]的秋景。」',
  },
  {
    title: '單句內單句(DD)',
    text: 'DD',
    value: 'DD',
    example:
      '單句中的單句，標點符號區隔的只是單句內部的單句關係，並以括弧標記被標點連接的範圍，例如：「設法[在悶熱的空氣中，分到一點點奢侈的涼風]。」',
  },
  {
    title: '名詞詞組(CZ)',
    text: 'CZ',
    value: 'CZ',
    example:
      '名詞組，標點符號區隔的是名詞組，例如：「一張俊俏的臉，一幀美麗的肖像，」有時需以括弧標記NP範圍，例如：「和[長期合作的夥伴，心理系梁庚辰老師]討論後，」',
  },
];
const statusList = [
  {
    text: '未校對',
    value: '未校對',
  },
  {
    text: '校對中',
    value: '校對中',
  },
  {
    text: '已校對',
    value: '已校對',
  },

  {
    text: '標記中',
    value: '標記中',
  },
  {
    text: '已標記',
    value: '已標記',
  },
  {
    text: '檢查中',
    value: '檢查中',
  },
  {
    text: '已檢查',
    value: '已檢查',
  },
  {
    text: '公開',
    value: '公開',
  },
  {
    text: '不公開',
    value: '不公開',
  },
];
const nameList = [
  {
    text: '開會',
    value: '開會',
    workreport: 'N',
    type: 'F',
  },
  {
    text: '待分配',
    value: '待分配',
    workreport: 'N',
    type: 'S',
  },

  {
    text: '喻方',
    value: '喻方',
    workreport: 'Y',
    type: 'B',
  },

  {
    text: '世平',
    value: '世平',
    workreport: 'Y',
    type: 'B',
  },
  {
    text: '品程',
    value: '品程',
    workreport: 'Y',
    type: 'B',
  },

  {
    text: '祖璇',
    value: '祖璇',
    workreport: 'Y',
    type: 'B',
  },
  {
    text: '淯琇',
    value: '淯琇',
    workreport: 'Y',
    type: 'B',
  },
  {
    text: '致穎',
    value: '致穎',
    workreport: 'Y',
    type: 'B',
  },

  {
    text: '老師',
    value: '老師',
    workreport: 'N',
    type: 'B',
  },
  {
    text: '免',
    value: '免',
    workreport: 'N',
    type: 'S',
  },
  {
    text: '芊孜',
    value: '芊孜',
    workreport: 'N',
    type: 'B',
  },
  {
    text: '由佳',
    value: '由佳',
    workreport: 'N',
    type: 'B',
  },
  {
    text: '育憬',
    value: '育憬',
    workreport: 'N',
    type: 'B',
  },
  {
    text: '孟仙',
    value: '孟仙',
    workreport: 'N',
    type: 'B',
  },
  {
    text: '宏碩',
    value: '宏碩',
    workreport: 'N',
    type: 'B',
  },
];

export {
  relationTree,
  classList,
  typeList,
  relationList,
  copyrightList,
  statusList,
  nameList,
  levelList,
  styleList,
  syntaxList,
};
