import React, { useEffect, useState } from 'react';
import { TabTitle } from './changeTitle';

import { Input, Table, Tabs, Radio, Typography, Modal } from 'antd';
import ScrollToTopButton from './ScrollToTopButton-component';
import {
  relationTree,
  relationList,
  typeList,
  levelList,
  styleList,
} from './searchOption-component';
// import RelationService from "../services/relation.service";
const { TextArea } = Input;
const { Text, Title, Paragraph } = Typography;

const DirectionsComponent = (props) => {
  let { currentUser, setCurrentUser } = props;
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState(null);

  useEffect(() => {
    TabTitle('標記說明');
    countRelationTree();
    handleGenre();
    setLoading(true);
    // console.log("entering into SourceService.get()!!!!");
    // RelationService.get()
    //   .then((res) => {
    //     // console.log(res.data);
    //     handleData(res.data);

    //     //  enableBeforeUnload();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  }, []);
  // const handleData = (data) => {
  //   let class1Name = '';
  //   let class2Name = '';
  //   const updatedData1 = data.map((item) => {
  //     if (item.class1 !== class1Name) {
  //       class1Name = item.class1;
  //       return item;
  //     }
  //     return { ...item, class1: '' };
  //   });
  //   const updatedData2 = updatedData1.map((item) => {
  //     if (item.class2 !== class2Name) {
  //       class2Name = item.class2;
  //       return item;
  //     }
  //     return { ...item, class2: '' };
  //   });
  //   console.log(updatedData2);
  //   setData(updatedData2);
  // };
  // const [editingKey, setEditingKey] = useState('');
  // const isEditing = (record) => record._id === editingKey;
  // //修改
  // const [form] = Form.useForm();
  // const edit = (record) => {
  //   form.setFieldsValue({
  //     ...record,
  //   });
  //   setEditingKey(record._id);
  // };
  //儲存修改
  // const save = async (record) => {
  //   try {
  //     const row = await form.validateFields();
  //     row._id = record._id;
  //     console.log(row);
  // RelationService.put(row)
  //   .then((res) => {
  //     console.log(res.data);
  //     // window.alert("此筆紀錄已修改至資料庫");
  //     Modal.success({
  //       title: "修改成功",
  //       content: `此筆紀錄已修改至資料庫。`,
  //       okText: "確認",
  //     });
  //     const newData = data.map((item) => {
  //       if (item._id === row._id) {
  //         return { ...item, example: row.example };
  //       } else {
  //         return item;
  //       }
  //     });
  //     console.log(newData);
  //     setData(newData);
  //     form.resetFields();
  //     // window.location.reload();
  //   })
  //   .catch((err) => {
  //     console.log(err.response.data);
  //     Modal.error({
  //       title: "修改失敗",
  //       content: `此筆紀錄未修改至資料庫。`,
  //       okText: "確認",
  //     });
  //     // window.alert("此筆紀錄未被修改至資料庫");

  //     window.location.reload();
  //   });
  //     setEditingKey('');
  //   } catch (errInfo) {
  //     console.log('Validate Failed:', errInfo);
  //   }
  // };
  // const columns = [
  //   {
  //     title: '大類',
  //     dataIndex: 'class1',
  //     width: '10w',
  //     render: (text, record) => {
  //       return <p style={{ width: '10vw' }}>{text}</p>;
  //     },
  //   },
  //   {
  //     title: '基本類',
  //     dataIndex: 'class2',
  //     width: '10w',
  //     render: (text, record) => {
  //       return <p style={{ width: '10vw' }}>{text}</p>;
  //     },
  //   },
  //   {
  //     title: '小類',
  //     dataIndex: 'class3',
  //     width: '10vw',
  //     render: (text, record) => {
  //       return <p style={{ width: '10vw' }}>{text}</p>;
  //     },
  //   },
  //   {
  //     title: '例句',
  //     dataIndex: 'example',
  //     width: '70vw',
  //     render: (text, record) => {
  //       const editable = isEditing(record);
  //       return editable ? (
  //         <div className='btn-group-vertical' role='group'>
  //           <Form.Item name='example'>
  //             <TextArea
  //               rows={4}
  //               style={{ fontSize: 18, whiteSpace: 'pre-wrap', width: '50vw' }}
  //             />
  //           </Form.Item>

  //           <div className='btn-group-toggle' role='group'>
  //             <button
  //               type='button'
  //               className='btn btn-primary btn-sm'
  //               onClick={() => save(record)}
  //               style={{ width: 'auto', fontSize: '14px' }}
  //             >
  //               保存
  //             </button>

  //             <button
  //               type='button'
  //               className='btn btn-outline-primary btn-sm'
  //               onClick={() => setEditingKey('')}
  //               style={{ width: 'auto', fontSize: '14px' }}
  //             >
  //               取消
  //             </button>
  //           </div>
  //         </div>
  //       ) : (
  //         <span className='btn-group-vertical' role='group'>
  //           <Paragraph style={{ fontSize: 18, whiteSpace: 'pre-wrap' }}>
  //             {text}
  //           </Paragraph>
  //           {currentUser.user.role === 'staff' && (
  //             <button
  //               type='button'
  //               className='btn btn-secondary btn-sm'
  //               onClick={() => edit(record)}
  //               style={{ width: 'auto', fontSize: '14px' }}
  //             >
  //               修改
  //             </button>
  //           )}
  //         </span>
  //       );
  //     },
  //   },
  // ];
  let [relationData, setRelationData] = useState([]);
  let [keywordData, setKeywordData] = useState([]);
  const countRelationTree = () => {
    let data = [];
    let Kdata = [];

    let saveName1;
    let rootName;
    let saveName2;
    let leafName;
    let class1Count = 0;
    let class2Count = 0;
    let class3Count = 0;

    relationTree.map((rel) => {
      class1Count += 1;
      // console.log(rel.title);
      for (let i = 0; i < rel.children.length; i++) {
        // console.log(rel.children[i].title);
        class2Count += 1;

        for (let k = 0; k < rel.children[i].children.length; k++) {
          // console.log(rel.children[i].children[k].title);
          class3Count += 1;

          if (rel.title == saveName1) {
            rootName = '';
          } else {
            rootName = rel.title;
            saveName1 = rel.title;
          }
          if (rel.children[i].title == saveName2) {
            leafName = '';
          } else {
            leafName = rel.children[i].title;
            saveName2 = rel.children[i].title;
          }
          //遇到/n換行
          let example = handleNewline(rel.children[i].children[k].example);

          let keyword = handleNewline(rel.children[i].children[k].keyword);
          data.push({
            class1:
              rootName == '' ? '' : toRomanNumeral(class1Count) + rootName,
            class2: leafName == '' ? '' : class2Count + leafName,

            class3: class3Count + '.' + rel.children[i].children[k].title,
            example: example,
          });
          Kdata.push({
            class1:
              rootName == '' ? '' : toRomanNumeral(class1Count) + rootName,
            class2: leafName == '' ? '' : class2Count + leafName,

            class3: class3Count + '.' + rel.children[i].children[k].title,
            keyword: keyword,
          });
        }
      }
    });
    setKeywordData(Kdata);
    setRelationData(data);
  };
  //遇到/n換行
  function handleNewline(line) {
    let newline = line.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
    return newline;
  }

  function toRomanNumeral(number) {
    const romanNumeralTable = {
      1000: 'M',
      900: 'CM',
      500: 'D',
      400: 'CD',
      100: 'C',
      90: 'XC',
      50: 'L',
      40: 'XL',
      10: 'X',
      9: 'IX',
      5: 'V',
      4: 'IV',
      1: 'I',
    };

    let result = '';

    for (const key of Object.keys(romanNumeralTable).sort((a, b) => b - a)) {
      while (number >= key) {
        result += romanNumeralTable[key];
        number -= key;
      }
    }

    return result;
  }
  let [genreData, setGenreData] = useState([]);
  const handleGenre = () => {
    let genreArr = [];
    let saveN = '';
    styleList.map((s) => {
      let str = '';
      let strArr = [];
      typeList.map((t) => {
        // console.log(s.value + t.value);
        // console.log(t.value);
        if (t.value !== '小說') {
          str = s.value + '性' + t.value;
          strArr.push(str);
        }
      });

      if (s.value !== saveN) {
        genreArr.push({
          style: s.value,
          mix: strArr,
          colSpan: 1,
        });
      } else {
        saveN = s.value;
      }
    });
    // console.log(genreArr);
    setGenreData(genreArr);
  };

  return (
    <div>
      <ScrollToTopButton />
      <br />
      <br />
      <div className='jumbotron'>
        <div className='container mb-5'>
          <h3 className='display-4 fs-3 fw-bold'>檢索與標記說明</h3>

          <hr />
          <p className='fs-5'>
            本語料庫共標記四方面訊息：
            <a href='#level'>句層</a>、<a href='#relation'>關係</a>、
            <a href='#keyword'>關聯詞</a>、<a href='#genre'>文類和文體</a>
            ，每筆檢索資料都會顯示前三方面訊息，文類和文體訊息則列於語料目錄。以下先介紹檢索方式，再說明這四方面訊息。
          </p>
        </div>
      </div>

      <div className='container mb-4'>
        <h3 className='display-5 fs-3 fw-bold '>檢索方式</h3>
        <div className='row'>
          <ol className='fs-5 '>
            <li>
              關係檢索：選擇複句或單句關係，可檢得語料庫中所有屬該關係之語料。可分別針對特定的大類、基本類或小類檢索，也可多選。
            </li>
            <li>
              句層檢索：選擇句層關係，可檢得語料庫中所有屬於該句層之語料，可多選。
            </li>
            <li>
              關聯詞檢索：可分別在前關聯詞和後關聯詞這兩個欄位檢索特定關聯詞，這兩個欄位各自只能填入一種關聯詞。預設為完全匹配檢索，如需查詢非完全匹配之詞彙，可於詞彙前後加上「.*」進行檢索。
            </li>
            <li>
              任意字串檢索：可分別在前語段或後語段這兩個欄位檢索特定詞彙或句子，這兩個欄位各自只能填入一段字串。
            </li>
            <li>文類和文體檢索：可分別挑選文類和文體，可多選。</li>
          </ol>
        </div>
        <h3 className='display-5 fs-3 fw-bold ' id='level'>
          一、句層
        </h3>
        <hr />
        <div className='row'>
          <p className='fs-5'>
            本語料庫以標點符號為語段區隔標記，語段之間除了複句關係，也可能含有句組、單句等關係，共區分以下六種類型。
          </p>

          <table
            style={{ width: '90%', textAlign: 'center' }}
            className='table table-bordered table-hover table-sm fs-5'
          >
            <thead className='thead-dark'>
              <tr>
                <th style={{ width: '10%' }}>標記</th>
                <th style={{ width: '90%' }}>說明</th>
              </tr>
            </thead>
            <tbody>
              {levelList.map((item) => (
                <tr>
                  <td>{item.value}</td>
                  <td style={{ textAlign: 'left' }}>{item.example}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className='container mb-5'>
        <h3 className='display-5 fs-3 fw-bold ' id='relation'>
          二、關係
        </h3>
        <hr />
        <div className='row'>
          <p className='fs-5'>
            本語料庫共區分6大類複句關係：並列類、承接類、解說類、因果類、條件類、讓轉類；各類並區分若干基本類，共有15個基本類：並列、選擇、遞進、承接、時間、解說、評論、總分、總結、因果、目的、假設、條件、讓步、轉折；各基本類下又區分小類，共得44小類。另外，因本語料庫也標記由標點區隔的單句關係，故也列有單句類，下分17小類，含句法及語義這兩方面的關係。
          </p>
          <p className='fs-5'>
            漢語複句經常同時含有兩種以上的關係，本語料庫優先根據所帶標記判定，如果兩種關係都帶有標記，便會標記兩種關係。像是下面這組例句，本語料庫根據所帶標記分別標記關係如下：
          </p>
          <ul className='fs-5 no-bullets'>
            <li>
              &emsp;&emsp;推論：
              <b>
                <u>既然</u>
              </b>
              不是活他自己的命，為什麼叫活命費？
            </li>
            <li>
              &emsp;&emsp;轉折：不是活他自己的命，為什麼
              <b>
                <u>卻</u>
              </b>
              叫活命費？
            </li>
            <li>
              &emsp;&emsp;推論+轉折：
              <b>
                <u>既然</u>
              </b>
              不是活他自己的命，為什麼
              <b>
                <u>卻</u>
              </b>
              叫活命費？
            </li>
          </ul>
          <p className='fs-5'>
            複句所含兩個語段均依照一定順序排列，像是「只要」語段和「雖然」語段均在前，如果有逆序情形，則在關係前添加「反」字，如下面兩個例句分別是「反條件」和「反實讓」。不過，原因語段置後情形相當普遍，學界普遍稱為釋因關係，本計畫從之。
          </p>
          <ul className='fs-5 no-bullets'>
            <li>&emsp;&emsp; 反條件：再娶一個，只要有錢；</li>
            <li>&emsp;&emsp; 反實讓：今晚却很好，雖然月光也還是淡淡的。</li>
          </ul>
          <p className='fs-5'>以下為本語料庫所區分之複句與單句關係：</p>
          <div>
            <table
              style={{ width: '90%', textAlign: 'center' }}
              className='table table-bordered table-hover table-sm fs-5'
            >
              <thead className='thead-dark'>
                <tr>
                  <th>大類</th>
                  <th>基本類</th>
                  <th>小類</th>
                  <th style={{ width: '50%' }}>例句</th>
                </tr>
              </thead>
              <tbody>
                {relationData.map((item) => (
                  <tr>
                    <td>{item.class1}</td>
                    <td>{item.class2}</td>
                    <td>{item.class3}</td>

                    <td style={{ textAlign: 'left' }}>{item.example}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <Form form={form} component={false}>
              <Table
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={false}
                size="small"
              ></Table>
            </Form> */}
          </div>
        </div>
      </div>
      <div className='container mb-5'>
        <h3 className='display-5 fs-3 fw-bold ' id='keyword'>
          三、關聯詞
        </h3>
        <hr />
        <div className='row'>
          <p className='fs-5'>
            針對每組複句，本語料庫亦標記起連接作用之標記，除了連詞與關聯副詞外，也包含能標記該關係之詞語，統稱為關聯詞。並根據其所在語段位置，區分前關聯詞與後關聯詞。由於語氣副詞也經常起著調節句間關係之作用，故也一併標記，特於其前添加Y，以示區別。
          </p>
          <p className='fs-5'>
            例如「正因為有他們當時的努力，我們這一個世代才得以安穩的從事臺灣史研究。」前語段的連詞「因為」以及後語段的關聯副詞「才」都具有連接作用。
          </p>
          <p className='fs-5 no-bullets'>
            需留意，關聯詞不必然用於連接所在句和前句或後句，有時連接範圍很廣，例如下面例句中的「也」連接前後兩大段落：「
            [臺大出版中心則邀請到哲學系林正弘教授與多位哲學先進，細數日治到戰後時期的臺灣哲學歷史，以瞭解我們自身的存在]。[
            <b>
              <u>也</u>
            </b>
            規劃由藝史所謝明良教授從藝術文化史的角度，談陶瓷修補技術及背後的意義]。」
          </p>
          <p className='fs-5'>
            以下為各類關係常見關聯詞，表中Q表前語段關聯詞，H表後語段關聯詞，QH表前後語段相互搭配之關聯詞。
          </p>
          <table
            style={{ width: '90%', textAlign: 'center' }}
            className='table table-bordered table-hover table-sm fs-5'
          >
            <thead className='thead-dark'>
              <tr>
                <th>大類</th>
                <th>基本類</th>
                <th>小類</th>
                <th style={{ width: '50%' }}>關聯詞及標記</th>
              </tr>
            </thead>
            <tbody>
              {keywordData.map((item) => (
                <tr>
                  <td>{item.class1}</td>
                  <td>{item.class2}</td>
                  <td>{item.class3}</td>
                  <td style={{ textAlign: 'left' }}>{item.keyword}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className='container mb-5'>
        <h3 className='display-5 fs-3 fw-bold ' id='genre'>
          四、文類和文體
        </h3>
        <hr />
        <div className='row'>
          <p className='fs-5'>
            本語料庫以現當代散文、報導、演講、小説這四種文類為範圍，再根據文體進行細分，共區分說明、描寫、記敘、論說、綜合五種，唯小説一律為記敘性。語料目錄前兩欄有這方面資料。
          </p>
          <div>
            <table
              style={{
                width: '80%',
                textAlign: 'center',
                border: '1px solid black',
              }}
              className='table table-bordered table-sm fs-5'
            >
              <tr>
                <td style={{ border: '1px solid black' }}>文體/文類</td>
                <td style={{ border: '1px solid black' }}>散文</td>
                <td style={{ border: '1px solid black' }}>報導</td>
                <td style={{ border: '1px solid black' }}>演講</td>
                <td style={{ border: '1px solid black' }} rowspan='6'>
                  　　小說　　
                </td>
                {/* {typeList.map((item) => (
                    <th style={{ textAlign: 'center' }}>{item.value}</th>
                  ))} */}
              </tr>
              {genreData.map((item) => (
                <tr>
                  <td style={{ border: '1px solid black' }}>{item.style}</td>
                  {item.mix.map((data) => (
                    <td style={{ border: '1px solid black' }}>{data}</td>
                  ))}
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectionsComponent;
