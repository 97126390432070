import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import FileService from '../services/file.service';
import * as XLSX from 'xlsx';

const SourceWindow = ({ currentUser, data, show, onHide }) => {
  let [message, setMessage] = useState('');
  let [loading, setLoading] = useState(false);
  let [name, setName] = useState('');

  const titleMapping = {
    // 最新標記: 'relation',
    文類: 'type',
    文體: 'style',
    編號: '_NO',
    作者篇名: 'author_title',
    出處: 'source',
    授權: 'copyright',
    狀態: 'status',
    校對者: 'proofreader',
    標記者: 'tagger',
    檢查者: 'reviser',
    原文: 'context',
  };
  const [file, setFile] = useState(null); // 用于存储选择的文件

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
    setName(selectedFile.name);
  };
  const [error, setError] = useState(null); // 用于存储错误信息

  const handleFileUpload = async () => {
    if (!file) {
      // setError('請先選擇一個檔案');
      window.alert('請先選擇一個檔案');
      return;
    }
    setLoading(true);
    setError(null);
    const reader = new FileReader();
    //取得已存在的編號
    // console.log(data);
    const existingSources = data.map((item) => item['_NO']);
    const existingTitle = data.map((item) => item['author_title']);
    // console.log(existingSources);
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // 读取第一个工作表
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        // 使用 header: 1 提取標題行和數據行
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headerRow = jsonData[0]; // 假設第一行是標題行
        const dataRows = jsonData.slice(1); // 其餘行是數據

        const wrongHeader = [];
        const requiredColumns = [
          '文類',
          '文體',
          '編號',
          '作者篇名',
          '出處',
          '授權',
          '狀態',
          '校對者',
          '標記者',
          '檢查者',
        ];

        // 先檢查標題列
        for (const col of requiredColumns) {
          if (!headerRow.includes(col)) {
            wrongHeader.push(col);
          }
        }

        if (wrongHeader.length > 0) {
          throw new Error(`缺少標題欄位: ${wrongHeader.join(', ')}`);
        }

        // 將數據行轉換回物件格式
        const formattedData = dataRows.map((row) => {
          return row.reduce((acc, value, index) => {
            acc[headerRow[index]] = value;
            return acc;
          }, {});
        });
        //暫存編號列
        const NumberList = [];
        //暫存篇名列
        const TitleList = [];
        //檢核資料
        const errorMessages = [];
        // 先篩選出 '編號' 和 '作者篇名' 有值的列，然後去除空白，並選擇特定的欄位
        const selectedColumns = formattedData
          .filter((row) => {
            // 過濾條件：檢查 '編號' 和 '作者篇名' 欄位是否有值
            return row['編號'] && row['作者篇名'];
          })
          .map((row, index) => {
            // 去除前後空白的欄位值
            const trimmedRow = {};
            Object.keys(row).forEach((key) => {
              trimmedRow[key] =
                typeof row[key] === 'string' ? row[key].trim() : row[key];
            });
            // 檢查編號是否重複
            if (existingSources.includes(trimmedRow['編號'])) {
              errorMessages.push(
                `第 ${index + 2} 列 - 編號 '${trimmedRow['編號']}' 已存在。`
              );
            }
            if (NumberList.includes(trimmedRow['編號'])) {
              errorMessages.push(
                `第 ${index + 2} 列 - 編號 '${
                  trimmedRow['編號']
                }' 與其他列重複。`
              );
            } else {
              NumberList.push(trimmedRow['編號']);
            }
            if (TitleList.includes(trimmedRow['作者篇名'])) {
              errorMessages.push(
                `第 ${index + 2} 列 - 篇名 '${
                  trimmedRow['作者篇名']
                }' 與其他列重複。`
              );
            } else {
              TitleList.push(trimmedRow['作者篇名']);
            }
            // 檢查篇名是否重複
            if (existingTitle.includes(trimmedRow['作者篇名'])) {
              errorMessages.push(
                `第 ${index + 2} 列 - 篇名 '${trimmedRow['作者篇名']}' 已存在。`
              );
            }
            if (!trimmedRow['文類']) {
              trimmedRow['文類'] = '未填';
            }
            if (!trimmedRow['文體']) {
              trimmedRow['文體'] = '未填';
            }
            if (!trimmedRow['狀態']) {
              trimmedRow['狀態'] = '未校對';
            }
            if (!trimmedRow['授權']) {
              trimmedRow['授權'] = '已授權';
            }
            if (!trimmedRow['標記者']) {
              trimmedRow['標記者'] = '待分配';
            }
            if (!trimmedRow['校對者']) {
              trimmedRow['校對者'] = '待分配';
            }
            if (!trimmedRow['檢查者']) {
              trimmedRow['檢查者'] = '待分配';
            }
            // 返回選定的欄位
            return {
              文類: trimmedRow['文類'],
              文體: trimmedRow['文體'],
              編號: trimmedRow['編號'],
              作者篇名: trimmedRow['作者篇名'],
              出處: trimmedRow['出處'],
              授權: trimmedRow['授權'],
              狀態: trimmedRow['狀態'],
              校對者: trimmedRow['校對者'],
              標記者: trimmedRow['標記者'],
              檢查者: trimmedRow['檢查者'],
              原文: trimmedRow['原文'],
            };
          });

        // 如果有錯誤，拋出異常
        if (errorMessages.length > 0) {
          const errorMessageHtml = errorMessages.join('<br />');
          setError(errorMessageHtml);
          // setLoading(false);
          // return;
          throw new Error('上傳資料有誤，請核對錯誤清單。');
        }

        // 转换列标题
        const transformedData = selectedColumns.map((row) => {
          return Object.keys(row).reduce((acc, key) => {
            const newKey = titleMapping[key] || key; // 使用映射后的英文标题，如果没有匹配则保留原标题
            acc[newKey] = row[key]; // 保持数据值不变
            return acc;
          }, {});
        });
        // 上传数据
        FileService.SourceUpload(currentUser, transformedData)
          .then((data) => {
            setLoading(false);
            window.alert('上傳成功!' + data);
            setName('');
            window.location.reload();
          })
          .catch((error) => {
            console.log(error.response);
            setLoading(false);
            setMessage(error.response.data);
            window.alert('上傳失敗!' + message);
          });
      } catch (error) {
        // 捕获错误并停止执行
        window.alert(error.message);
        setLoading(false);
        return;
        // window.location.reload(); // 重新加载页面
      }
    };

    reader.readAsArrayBuffer(file);
  };
  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      {...{ show, onHide }}
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>新增語料</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <br />

          <div class='input-group'>
            <input
              type='file'
              class='form-control'
              id='inputGroupFile04'
              aria-describedby='inputGroupFileAddon04'
              aria-label='Upload'
              name={name}
              onChange={handleFileChange}
              accept='.xlsx, .xls'
            />
            {!loading && (
              <button
                class='btn btn-outline-secondary'
                type='button'
                id='inputGroupFileAddon04'
                onClick={handleFileUpload}
              >
                上傳
              </button>
            )}
            {loading && (
              <Button variant='dark' disabled>
                <Spinner
                  as='span'
                  variant='light'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                  animation='border'
                />
                上傳中
              </Button>
            )}
          </div>
          <br />
        </div>
        <span>
          <h6>
            ※標題:文類、文體、文章編號(必填)、作者篇名(必填)、出處、授權、狀態、校對者、標記者、檢查者、原文
          </h6>
          <h6>
            ※以下欄位若空白預設填入值：文類、文體=「未填」；校對者、標記者、檢查者=「待分配」；授權=已授權；狀態=未校對。
          </h6>
          <h6>※原文欄位可以直接貼上原文，亦可在原文閱覽頁面更改。</h6>
          <h6>※如資料有誤無法上傳，請先根據錯誤清單排除錯誤原因後再上傳。</h6>
          {error && (
            <>
              <hr />
              <h5>
                <b>錯誤清單</b>
              </h5>
              <h6 dangerouslySetInnerHTML={{ __html: error }} />
            </>
          )}
        </span>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='outline-secondary' onClick={onHide}>
          關閉
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default SourceWindow;
