import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import FileService from '../services/file.service';
import { TabTitle } from './changeTitle';
import { Table, Modal, Form, Select } from 'antd';
import fileService from '../services/file.service';
const { Option } = Select;

const UserListComponent = (props) => {
  let { currentUser, setCurrentUser } = props;
  let [data, setData] = useState([]);

  useEffect(() => {
    //取得資料
    if (currentUser && currentUser.user.role === 'admin') {
      TabTitle('訪客管理');
      setLoading(true);
      FileService.getUserList()
        .then((res) => {
          setData(res.data);
          // handleData(data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      window.alert('沒有權限');
      setLoading(false);
    }
  }, []);
  let [publicTable, setPublicTable] = useState([]);
  let [staffTable, setStaffTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  //是否有修改
  const isEditing = (record) => record._id === editingKey;

  const [form] = Form.useForm();
  //修改
  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    console.log(record);
    setEditingKey(record._id);
  };
  //取消動作
  const cancel = (record) => {
    // console.log(`record: ${record}`);
    setEditingKey('');
  };
  //儲存修改
  const save = async (record) => {
    try {
      const row = await form.validateFields();
      row._id = record._id;
      row.email = record.email;
      row.username = record.username;
      row.background = record.background;
      row.purpose = record.purpose;
      // console.log(row);
      // const newSourceData = [...sourceData];

      //edit
      // console.log(`isNewData ${isNewData}`);
      fileService
        .put(row)
        .then((res) => {
          // console.log(res.data);

          // window.alert("此筆紀錄已修改至資料庫");
          Modal.success({
            title: '修改成功',
            content: `此筆紀錄已修改至資料庫。`,
            okText: '確認',
          });
          const newdata = data.map((u) => (u._id === row._id ? row : u));
          // console.log(newdata);
          setData(newdata);
          // window.location.reload();
        })
        .catch((err) => {
          console.log(err.response.data);
          Modal.error({
            title: '修改失敗',
            content: `此筆紀錄未修改至資料庫。`,
            okText: '確認',
          });
          // window.alert("此筆紀錄未被修改至資料庫");
          window.location.reload();
        });
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  const publicColum = [
    {
      title: '狀態',
      key: 'role',
      dataIndex: 'role',
      width: '6vw',
      render: (text, record) => {
        const editable = isEditing(record);

        return editable ? (
          <Form.Item name='role'>
            <Select initialValue='' style={{ fontSize: '20px' }}>
              <Option key='guest' value='guest'>
                已審核
              </Option>
              <Option key='stranger' value='stranger'>
                未審核
              </Option>
            </Select>
          </Form.Item>
        ) : (
          <p style={{ width: '5vw' }}>
            {text === 'guest' ? '已審核' : '未審核'}
          </p>
        );
      },
    },
    {
      title: '學校/單位',
      key: 'background',
      dataIndex: 'background',
      width: '6vw',
    },

    {
      title: '姓名',
      key: 'username',
      dataIndex: 'username',
      width: '6vw',
    },

    {
      title: '信箱',
      key: 'email',
      dataIndex: 'email',
      width: '10vw',
    },

    {
      title: '用途',
      key: 'purpose',
      dataIndex: 'purpose',
      width: '6vw',
    },
    {
      title: '編輯選項',
      key: 'operation',
      dataIndex: 'operation',
      width: '10vw',
      editable: true,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div className='btn-group' role='group'>
            <button
              type='button'
              className='btn btn-primary btn-sm'
              onClick={() => save(record)}
            >
              保存
            </button>

            <button
              type='button'
              className='btn btn-outline-primary btn-sm'
              onClick={() => cancel(record)}
            >
              取消
            </button>
          </div>
        ) : (
          <div className='btn-group' role='group'>
            <button
              type='button'
              className='btn btn-warning btn-sm '
              data-toggle='button'
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              修改
            </button>
          </div>
        );
      },
    },
  ];

  const handleData = (data) => {
    let dataP = [];
    let dataS = [];

    data.map((user) => {
      if (user.role === 'stranger') {
        dataP.push({
          id: user._id,
          status: '未審核',
          username: user.username,
          email: user.email,
          background: user.background,
        });
      }
      if (user.role === 'guest') {
        dataP.push({
          id: user._id,
          status: '已審核',
          username: user.username,
          email: user.email,
          background: user.background,
        });
      }
      // if (user.role === 'staff') {
      //   dataS.push({
      //     id: user._id,
      //     role: '內部',
      //     username: user.username,
      //     email: user.email,
      //   });
      // }
    });

    setStaffTable(dataS);
    setPublicTable(dataP);
    setData(dataP);
    console.log(dataP);
  };

  return (
    <div>
      <br />
      <div className='container'>
        <h3 className='display-4 fs-3 fw-bold'>訪客管理</h3>
        <hr />
        <div className='row align-items-start'>
          <div className='col'>
            <Form form={form} component={false}>
              <Table
                loading={loading}
                bordered
                dataSource={data}
                columns={publicColum}
                pagination={false}
                style={{ fontSize: 20 }}
              />
            </Form>
          </div>
          {/* <div className='col'>
            <Table
              loading={loading}
              bordered
              dataSource={staffTable}
              columns={staffColum}
              pagination={false}
              style={{ fontSize: 20 }}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default UserListComponent;
