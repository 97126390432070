import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Chart } from 'react-google-charts';
import FuguService from '../services/fugu.service';
import SourceService from '../services/source.service';
import ScrollToTopButton from './ScrollToTopButton-component';
import { Button } from 'react-bootstrap';
import {
  relationTree,
  relationList,
  typeList,
  levelList,
  styleList,
  classList,
} from './searchOption-component';
import 'antd/dist/antd.css';
// import 'antd/dist/reset.css';
import { Table, Tabs, Radio, Typography, Modal } from 'antd';
import { TabTitle } from './changeTitle';
const { Text } = Typography;

const AnalysisComponent = (props) => {
  let { currentUser, setCurrentUser } = props;

  const history = useNavigate();
  const handleTakeToLogin = () => {
    history('/login');
  };
  const handle401 = (err) => {
    if (err.response.status === 401) {
      const modal = Modal.confirm({
        title: '權限已失效',
        content: `權限已失效，請重新登入。`,
        okText: '登入',
        cancelText: '取消',
        onOk: () => {
          modal.destroy();
          history('/login');
        },
        onCancel: () => {
          history('/');
        },
      });
    }
  };
  let [loading, setLoading] = useState(true);

  // 實際複句標記(遞增排序)
  const fujuColumns = [
    {
      title: '序號',
      key: 'key',
      dataIndex: 'key',
      width: '3vw',
    },

    {
      title: '關係',
      key: 'relation',
      dataIndex: 'relation',
      width: '6vw',
    },
    {
      title: '標記數',
      key: 'Count',
      dataIndex: 'Count',
      width: '8vw',
    },
  ];

  //複句關係統計(按分類排序)
  const TreeColumns = [
    {
      title: '序號',
      key: 'key',
      dataIndex: 'key',
      width: '3vw',
    },
    {
      title: '大類',
      key: 'root',
      dataIndex: 'root',
      width: '6vw',
    },
    {
      title: '次類',
      key: 'leaf',
      dataIndex: 'leaf',
      width: '6vw',
    },

    {
      title: '關係',
      key: 'relation',
      dataIndex: 'relation',
      width: '6vw',
    },
    {
      title: '標記數',
      key: 'Count',
      dataIndex: 'Count',
      width: '8vw',
    },
  ];

  //關聯詞統計(按分類排序)
  const keywordColumns = [
    {
      title: '複句關係',
      key: 'relation',
      dataIndex: 'relation',
      width: '8vw',
    },
    {
      title: '前關聯詞',
      key: 'formerkey',
      dataIndex: 'formerkey',
      width: '20vw',
    },
    {
      title: '後關聯詞',
      key: 'latterkey',
      dataIndex: 'latterkey',
      width: '20vw',
    },
    {
      title: '前語氣副詞',
      key: 'Yformerkey',
      dataIndex: 'Yformerkey',
      width: '20vw',
    },
    {
      title: '後語氣副詞',
      key: 'Ylatterkey',
      dataIndex: 'Ylatterkey',
      width: '20vw',
    },
  ];
  //關聯詞統計(總數)
  const keywordColumnsA = [
    {
      title: '序號',
      key: 'order',
      dataIndex: 'order',
      width: '3vw',
    },
    {
      title: '前關聯詞',
      key: 'formerkey',
      dataIndex: 'formerkey',
      width: '6vw',
    },
    {
      title: '出現次數',
      key: 'CountF',
      dataIndex: 'CountF',
      width: '8vw',
    },
    {
      title: '後關聯詞',
      key: 'latterkey',
      dataIndex: 'latterkey',
      width: '6vw',
    },
    {
      title: '出現次數',
      key: 'CountL',
      dataIndex: 'CountL',
      width: '8vw',
    },
  ];
  //關聯詞統計(按句層排序)
  const keywordColumnsL = [
    {
      title: '句層',
      key: 'level',
      dataIndex: 'level',
      width: '8vw',
    },
    {
      title: '前關聯詞',
      key: 'formerkey',
      dataIndex: 'formerkey',
      width: '20vw',
    },
    {
      title: '後關聯詞',
      key: 'latterkey',
      dataIndex: 'latterkey',
      width: '20vw',
    },
    {
      title: '前語氣副詞',
      key: 'Yformerkey',
      dataIndex: 'Yformerkey',
      width: '20vw',
    },
    {
      title: '後語氣副詞',
      key: 'Ylatterkey',
      dataIndex: 'Ylatterkey',
      width: '20vw',
    },
  ];

  //實際句層標記(遞增排序)
  const sentenceColumns = [
    {
      title: '句層',
      key: 'sentencelevel',
      dataIndex: 'sentencelevel',
      width: '6vw',
    },
    {
      title: '標記數',
      key: 'Count',
      dataIndex: 'Count',
      width: '8vw',
    },
  ];
  //句層標記(遞增排序)
  const levelColumns = [
    {
      title: '句層',
      key: 'sentencelevel',
      dataIndex: 'sentencelevel',
      width: '6vw',
    },
    {
      title: '標記數',
      key: 'allCount',
      dataIndex: 'allCount',
      width: '8vw',
    },
    {
      title: '已授權標記數',
      key: 'Count',
      dataIndex: 'Count',
      width: '8vw',
    },
  ];
  //文章標記量
  const sourceColumns = [
    {
      title: '篇號',
      key: '_NO',
      dataIndex: '_NO',
      width: '10vw',
    },
    {
      title: '文類/文體',
      key: 'genre',
      dataIndex: 'genre',
      width: '10vw',
    },
    {
      title: '作者篇名',
      key: 'source',
      dataIndex: 'source',
      width: '20vw',
    },
    {
      title: '授權',
      key: 'copyright',
      dataIndex: 'copyright',
      width: '10vw',
    },
    {
      title: '標記數',
      key: 'Count',
      dataIndex: 'Count',
      width: '10vw',
    },
  ];
  //文類標記量
  const typeColumns = [
    {
      title: '文類',
      key: 'type',
      dataIndex: 'type',
      width: '5vw',
    },

    {
      title: '篇數',
      key: 'number',
      dataIndex: 'number',
      width: '5vw',
    },
    {
      title: '總標記數',
      key: 'CountAll',
      dataIndex: 'CountAll',
      width: '5vw',
    },
    {
      title: '已授權標記數',
      key: 'Count',
      dataIndex: 'Count',
      width: '7vw',
    },
  ];
  //文體標記量

  const styleColumns = [
    {
      title: '文體',
      key: 'style',
      dataIndex: 'style',
      width: '5vw',
    },
    {
      title: '篇數',
      key: 'number',
      dataIndex: 'number',
      width: '5vw',
    },
    {
      title: '總標記數',
      key: 'CountAll',
      dataIndex: 'CountAll',
      width: '5vw',
    },
    {
      title: '已授權標記數',
      key: 'Count',
      dataIndex: 'Count',
      width: '7vw',
    },
  ];

  //統計圖欄位
  let [pieOptions, setPieOptions] = useState({});
  let [pieData, setPieData] = useState([]);
  let relationPie1 = [['大類', '標記數']];
  let relationPie2 = [['次類', '標記數']];
  let typePie = [['文類', '標記數']];
  let stylePie = [['文體', '標記數']];
  let op1 = {
    title: '複句大類統計',
    pieSliceText: 'label',
  };

  let op2 = {
    title: '複句次類統計',
    pieSliceText: 'label',
  };
  let op3 = {
    title: '文章類型統計',
    pieSliceText: 'label',
  };

  let [relation1, setrelation1] = useState([]);
  let [relation2, setrelation2] = useState([]);
  let [type, setType] = useState([]);
  let [style, setStyle] = useState([]);
  let [auth, setAuth] = useState(false);

  useEffect(() => {
    //查找資料庫
    if (
      currentUser.user.role === 'staff' ||
      currentUser.user.role === 'admin'
    ) {
      setAuth(true);
      TabTitle('語料統計');
      handleData();
    } else {
      window.alert('沒有權限');
    }
  }, []);

  let [total, setTotal] = useState(null);
  let [totalF, setTotalF] = useState(null);
  let [totalL, setTotalL] = useState(null);
  let [totalF_K, setTotalF_K] = useState(null);
  let [totalL_K, setTotalL_K] = useState(null);
  let [data, setData] = useState([]);
  let [dataAll, setDataAll] = useState([]);
  let [tableData, setTableData] = useState([]);
  let [keywordDataA, setKeywordDataA] = useState([]);
  let [keywordDataR, setKeywordDataR] = useState([]);
  let [keywordDataS, setKeywordDataS] = useState([]);
  let [table2Data, setTable2Data] = useState([]);
  let [SourceData, setSourceData] = useState([]);
  let [typeData, setTypeData] = useState([]);
  let [classData, setClassData] = useState([]);
  let [styleData, setStyleData] = useState([]);
  let [levelData, setLevelData] = useState([]);
  let [level2Data, setLevel2Data] = useState([]);

  const handleData = async () => {
    let fujuData;
    await FuguService.countRelationAll()
      .then((data) => {
        // console.log(data);
        setDataAll(data);
        fujuData = data;
      })
      .catch((err) => {
        handle401(err);
        console.log(err);
      })
      .finally(() => {
        // countParent();
        setLoading(false);
      });
    await FuguService.countRelation()
      .then((data) => {
        // console.log(data);
        setData(data);
      })
      .catch((err) => {
        handle401(err);

        console.log(err);
      })
      .finally(() => {
        // countParent();
        setLoading(false);
      });
    let sourceCount;
    await FuguService.countSource()
      .then((data) => {
        // console.log(data);
        sourceCount = data;
      })
      .catch((err) => {
        handle401(err);

        console.log(err);
      })
      .finally(() => {
        // countParent();
        setLoading(false);
      });
    let level;

    await FuguService.countLevel()
      .then((data) => {
        // console.log(data);
        level = data;
      })
      .catch((err) => {
        handle401(err);
        console.log(err);
      })
      .finally(() => {
        // countParent();
        setLoading(false);
      });
    let allLevel;

    await FuguService.countAllLevel()
      .then((data) => {
        // console.log(data);
        allLevel = data;
      })
      .catch((err) => {
        handle401(err);
        console.log(err);
      })
      .finally(() => {
        // countParent();
        setLoading(false);
      });
    let source;
    await SourceService.get()
      .then((res) => {
        // console.log(res.data);
        source = res.data;
        // setSourceData(res.data);
      })
      .catch((err) => {
        handle401(err);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
    let formerkeyA;
    await FuguService.countFormerKeyA()
      .then((data) => {
        // console.log(data);
        formerkeyA = data;
      })
      .catch((err) => {
        handle401(err);
        // console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
    let latterkeyA;
    await FuguService.countLatterKeyA()
      .then((data) => {
        // console.log(data);
        latterkeyA = data;
      })
      .catch((err) => {
        handle401(err);
        // console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
    let formerkey;
    await FuguService.countFormerKey()
      .then((data) => {
        // console.log(data);
        formerkey = data;
      })
      .catch((err) => {
        handle401(err);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
    let formerkey_s;
    await FuguService.countFormerKey_S()
      .then((data) => {
        // console.log(data);
        formerkey_s = data;
      })
      .catch((err) => {
        handle401(err);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
    let latterkey;
    await FuguService.countLatterKey()
      .then((data) => {
        // console.log(data);
        latterkey = data;
      })
      .catch((err) => {
        handle401(err);
        // console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
    let latterkey_s;
    await FuguService.countLatterKey_S()
      .then((data) => {
        // console.log(data);
        latterkey_s = data;
      })
      .catch((err) => {
        handle401(err);
        // console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

    // 計算文類/文體
    function calculateCounts(list, source, sourceCount, key) {
      // console.log(sourceCount);
      // console.log(list);
      // console.log(source);
      const sourceArray = [];
      const resultArray = [];
      const pieData = [];

      for (let k = 0; k < list.length; k++) {
        let sourceNum = 0;
        let sourceNumAll = 0;
        let number = 0;

        for (let i = 0; i < source.length; i++) {
          let count = 0;
          let countAll = 0;

          if (list[k].text === source[i][key]) {
            number++; // 每當匹配到一篇文章，計數增加 1
            // console.log(list[k].text);
            for (let j = 0; j < sourceCount.length; j++) {
              if (sourceCount[j]._id.includes(source[i].author_title)) {
                countAll = sourceCount[j].count;
                if (source[i].copyright === '已授權') {
                  count = sourceCount[j].count;
                  sourceNum += count;
                }
                sourceNumAll += countAll;
              }
            }
          }
        }

        pieData.push([list[k].text, sourceNumAll]);
        resultArray.push({
          type: list[k].text,
          style: list[k].text,
          CountAll: sourceNumAll,
          number: number,
          Count: sourceNum,
        });
      }
      console.log(resultArray);

      return { sourceArray, resultArray, pieData };
    }

    function generateSourceArray(source, sourceCount) {
      const sourceArray = [];

      for (let i = 0; i < source.length; i++) {
        let countAll = 0;

        for (let j = 0; j < sourceCount.length; j++) {
          if (sourceCount[j]._id.includes(source[i].author_title)) {
            countAll = sourceCount[j].count;
            sourceArray.push({
              _NO: source[i]._NO,
              genre: source[i].type + '/' + source[i].style,
              source: source[i].author_title,
              copyright: source[i].copyright,
              Count: countAll,
            });
            break;
          }
        }
      }

      // 按 `_NO` 排序
      return sourceArray.sort((a, b) => a._NO - b._NO);
    }
    // 計算文類七類數量
    function calculateClassCounts(classList, source, sourceCount) {
      const resultArray = [];
      const pieData = [];

      for (let k = 0; k < classList.length; k++) {
        let sourceNum = 0;
        let sourceNumAll = 0;
        let number = 0;

        for (let i = 0; i < source.length; i++) {
          let count = 0;
          let countAll = 0;

          // 檢查 _NO 是否以 classList 的值開頭
          if (source[i]._NO.startsWith(classList[k].value)) {
            number++; // 每當匹配到一篇文章，計數增加 1
            for (let j = 0; j < sourceCount.length; j++) {
              if (sourceCount[j]._id.includes(source[i].author_title)) {
                countAll = sourceCount[j].count;

                // 累加標記量
                if (source[i].copyright === '已授權') {
                  count = sourceCount[j].count;
                  sourceNum += count;
                }
                sourceNumAll += countAll;
                break;
              }
            }
          }
        }

        // 計算結果
        pieData.push([classList[k].text, sourceNumAll]);
        resultArray.push({
          type: classList[k].title,
          CountAll: sourceNumAll,
          Count: sourceNum,
          number: number,
        });
      }

      return { resultArray, pieData };
    }
    if (source && sourceCount) {
      const { resultArray: typeArray, pieData: typePie } = calculateCounts(
        typeList,
        source,
        sourceCount,
        'type'
      );
      const { resultArray: styleArray, pieData: stylePie } = calculateCounts(
        styleList,
        source,
        sourceCount,
        'style'
      );
      // 一次性生成 sourceArray
      const sourceArray = generateSourceArray(source, sourceCount);
      // 計算每個 class 的標記量
      const { resultArray: classArray, pieData: classPie } =
        calculateClassCounts(classList, source, sourceCount);
      // console.log(classPie);
      // 處理計算結果

      setSourceData(sourceArray);
      setClassData(classArray);
      setTypeData(typeArray);
      setType(typePie);
      setStyleData(styleArray);
      setStyle(stylePie);
    }

    //統計實際複句標記量
    countRelation(fujuData);

    //統計複句關係(按分類)
    countRelationTree(fujuData);

    //歸納關聯詞(總數)//////////////////////////////////////////////////////
    let tableF = [];
    let tableL = [];
    let YtableF = [];
    let YtableL = [];

    for (let i = 0; i < formerkeyA.length; i++) {
      if (formerkeyA[i]._id !== '' && formerkeyA[i]._id !== undefined) {
        // console.log(formerkeyA[i]);
        //按頓號分割詞
        if (formerkeyA[i]._id.includes('、')) {
          let str = formerkeyA[i]._id;
          let newStr = str.split('、');
          // console.log(newStr);
          for (let x = 0; x < newStr.length; x++) {
            //抓出語氣副詞(開頭Y的)
            if (newStr[x].includes('Y')) {
              let newStr2 = newStr[x].replace('Y', '');
              YtableF.push({ key: newStr2, count: 1 });
            } else {
              tableF.push({
                key: newStr[x],
                count: 1,
              });
            }
          }
        } else {
          if (formerkeyA[i]._id.includes('Y')) {
            let newStr2 = formerkeyA[i]._id.replace('Y', '');
            YtableF.push({
              key: newStr2,
              count: formerkeyA[i].count,
            });
          } else {
            tableF.push({
              key: formerkeyA[i]._id,
              count: formerkeyA[i].count,
            });
          }
        }
      }
    }
    for (let i = 0; i < latterkeyA.length; i++) {
      if (latterkeyA[i]._id !== '' && latterkeyA[i]._id !== undefined) {
        // console.log(formerkeyA[i]);
        //按頓號分割詞
        if (latterkeyA[i]._id.includes('、')) {
          let str = latterkeyA[i]._id;
          let newStr = str.split('、');
          // console.log(newStr);
          for (let x = 0; x < newStr.length; x++) {
            //抓出語氣副詞(開頭Y的)
            if (newStr[x].includes('Y')) {
              let newStr2 = newStr[x].replace('Y', '');
              YtableL.push({ key: newStr2, count: 1 });
            } else {
              tableL.push({
                key: newStr[x],
                count: 1,
              });
            }
          }
        } else {
          if (latterkeyA[i]._id.includes('Y')) {
            let newStr2 = latterkeyA[i]._id.replace('Y', '');
            YtableL.push({
              key: newStr2,
              count: latterkeyA[i].count,
            });
          } else {
            tableL.push({
              key: latterkeyA[i]._id,
              count: latterkeyA[i].count,
            });
          }
        }
      }
    }
    let ArrF = [];
    let ArrL = [];
    let totalnum_F = 0;
    let totalnum_L = 0;
    let totalnum_F_K = 0;
    let totalnum_L_K = 0;

    tableF = countKeywordN(tableF);
    tableF.map((k) => {
      // console.log(k);
      totalnum_F = totalnum_F + k.count;
      totalnum_F_K++;
      ArrF.push({
        order: totalnum_F_K,
        formerkey: k.key,
        CountF: k.count,
      });
    });
    tableL = countKeywordN(tableL);
    tableL.map((k) => {
      totalnum_L = totalnum_L + k.count;
      totalnum_L_K++;
      ArrL.push({
        order: totalnum_L_K,
        latterkey: k.key,
        CountL: k.count,
      });
    });
    const keyArr = ArrF.map((item1) => {
      const matchingItem = ArrL.find((item2) => item2.order === item1.order);
      // 如果有匹配的项，将它们合并
      if (matchingItem) {
        return { ...item1, ...matchingItem };
      }
      // 如果没有匹配的项，只返回原始项
      return item1;
    });

    // console.log(keyArr);
    setKeywordDataA(keyArr);
    setTotalF(totalnum_F);
    setTotalL(totalnum_L);
    setTotalF_K(totalnum_F_K);
    setTotalL_K(totalnum_L_K);

    //歸納關聯詞(複句關係)//////////////////////////////////////////////////////
    let relArr = [];
    relationTree.map((rel) => {
      // console.log(rel.title);

      for (let i = 0; i < rel.children.length; i++) {
        // console.log(rel.children[i]);

        for (let k = 0; k < rel.children[i].children.length; k++) {
          // console.log(rel.children[i].children[k].title);
          let name = rel.children[i].children[k].title;
          let formerkeyArray = [];
          let YformerkeyArray = [];
          let latterkeyArray = [];
          let YlatterkeyArray = [];
          //前關聯詞
          for (let l = 0; l < formerkey.length; l++) {
            // console.log(formerkey[l]);
            if (
              formerkey[l]._id.formerkey !== '' &&
              formerkey[l]._id.formerkey !== undefined &&
              formerkey[l]._id.relation !== '' &&
              formerkey[l]._id.relation !== undefined &&
              formerkey[l]._id.relation.includes(name)
            ) {
              //按頓號分割詞
              if (formerkey[l]._id.formerkey.includes('、')) {
                let str = formerkey[l]._id.formerkey;
                let newStr = str.split('、');
                // console.log(newStr);
                for (let x = 0; x < newStr.length; x++) {
                  // console.log(newStr[x]);
                  //抓出語氣副詞(開頭Y的)
                  if (newStr[x].includes('Y')) {
                    let newStr2 = newStr[x].replace('Y', '');
                    YformerkeyArray.push({ key: newStr2, count: 1 });
                  } else {
                    formerkeyArray.push({
                      key: newStr[x],
                      count: 1,
                    });
                  }
                }
              } else {
                if (formerkey[l]._id.formerkey.includes('Y')) {
                  let newStr2 = formerkey[l]._id.formerkey.replace('Y', '');
                  YformerkeyArray.push({
                    key: newStr2,
                    count: formerkey[l].count,
                  });
                } else {
                  formerkeyArray.push({
                    key: formerkey[l]._id.formerkey,
                    count: formerkey[l].count,
                  });
                }
              }
              // console.log(
              //   `${name}:${formerkey[l]._id.formerkey},${formerkey[l].count}`
              // );
            }
          }
          //後關聯詞
          for (let l = 0; l < latterkey.length; l++) {
            if (
              latterkey[l]._id.latterkey !== '' &&
              latterkey[l]._id.latterkey !== undefined &&
              latterkey[l]._id.relation !== '' &&
              latterkey[l]._id.relation !== undefined &&
              latterkey[l]._id.relation.includes(name)
            ) {
              //按頓號分割詞
              if (latterkey[l]._id.latterkey.includes('、')) {
                let str = latterkey[l]._id.latterkey;
                let newStr = str.split('、');
                // console.log(newStr);
                for (let x = 0; x < newStr.length; x++) {
                  // console.log(newStr[x]);
                  //抓出語氣副詞(開頭Y的)
                  if (newStr[x].includes('Y')) {
                    let newStr2 = newStr[x].replace('Y', '');
                    YlatterkeyArray.push({
                      key: newStr2,
                      count: 1,
                    });
                  } else {
                    latterkeyArray.push({
                      key: newStr[x],
                      count: 1,
                    });
                  }
                }
              } else {
                if (latterkey[l]._id.latterkey.includes('Y')) {
                  let newStr2 = latterkey[l]._id.latterkey.replace('Y', '');
                  YlatterkeyArray.push({
                    key: newStr2,
                    count: latterkey[l].count,
                  });
                } else {
                  latterkeyArray.push({
                    key: latterkey[l]._id.latterkey,
                    count: latterkey[l].count,
                  });
                }
              }
              // console.log(
              //   `${name}:${latterkey[l]._id.latterkey},${latterkey[l].count}`
              // );
            }
          }
          relArr.push({
            relation: name,
            formerA: formerkeyArray,
            latterA: latterkeyArray,
            YformerA: YformerkeyArray,
            YlatterA: YlatterkeyArray,
          });
        }
      }
    });

    relArr.map((item) => {
      // console.log(item.latterA);
      item.formerkey = countKeyword(item.formerA);
      item.latterkey = countKeyword(item.latterA);
      item.Yformerkey = countKeyword(item.YformerA);
      item.Ylatterkey = countKeyword(item.YlatterA);
    });

    // setKeywordData(relArr);
    setKeywordDataR(relArr);
    // console.log(Y_keyword);

    //歸納關聯詞(句層)

    let senArr = [];
    levelList.map((level) => {
      // console.log(level.text);
      let name = level.text;
      let formerkeyArray_s = [];
      let YformerkeyArray_s = [];
      let latterkeyArray_s = [];
      let YlatterkeyArray_s = [];
      //前關聯詞
      for (let l = 0; l < formerkey_s.length; l++) {
        if (
          formerkey_s[l]._id.formerkey !== '' &&
          formerkey_s[l]._id.formerkey !== undefined &&
          formerkey_s[l]._id.sentencelevel.includes(name)
        ) {
          //按頓號分割詞
          if (formerkey_s[l]._id.formerkey.includes('、')) {
            let str = formerkey_s[l]._id.formerkey;
            let newStr = str.split('、');
            // console.log(newStr);
            for (let x = 0; x < newStr.length; x++) {
              // console.log(newStr[x]);
              //抓出語氣副詞(開頭Y的)
              if (newStr[x].includes('Y')) {
                let newStr2 = newStr[x].replace('Y', '');
                YformerkeyArray_s.push({ key: newStr2, count: 1 });
              } else {
                formerkeyArray_s.push({
                  key: newStr[x],
                  count: 1,
                });
              }
            }
          } else {
            if (formerkey_s[l]._id.formerkey.includes('Y')) {
              let newStr2 = formerkey_s[l]._id.formerkey.replace('Y', '');
              YformerkeyArray_s.push({
                key: newStr2,
                count: formerkey_s[l].count,
              });
            } else {
              formerkeyArray_s.push({
                key: formerkey_s[l]._id.formerkey,
                count: formerkey_s[l].count,
              });
            }
          }
          // console.log(
          //   `${name}:${formerkey_s[l]._id.formerkey},${formerkey_s[l].count}`
          // );
        }
      }
      //後關聯詞
      for (let l = 0; l < latterkey_s.length; l++) {
        if (
          latterkey_s[l]._id.latterkey !== '' &&
          latterkey_s[l]._id.latterkey !== undefined &&
          latterkey_s[l]._id.sentencelevel.includes(name)
        ) {
          //按頓號分割詞
          if (latterkey_s[l]._id.latterkey.includes('、')) {
            let str = latterkey_s[l]._id.latterkey;
            let newStr = str.split('、');
            // console.log(newStr);
            for (let x = 0; x < newStr.length; x++) {
              // console.log(newStr[x]);
              //抓出語氣副詞(開頭Y的)
              if (newStr[x].includes('Y')) {
                let newStr2 = newStr[x].replace('Y', '');
                YlatterkeyArray_s.push({
                  key: newStr2,
                  count: 1,
                });
              } else {
                latterkeyArray_s.push({
                  key: newStr[x],
                  count: 1,
                });
              }
            }
          } else {
            if (latterkey_s[l]._id.latterkey.includes('Y')) {
              let newStr2 = latterkey_s[l]._id.latterkey.replace('Y', '');
              YlatterkeyArray_s.push({
                key: newStr2,
                count: latterkey[l].count,
              });
            } else {
              latterkeyArray_s.push({
                key: latterkey_s[l]._id.latterkey,
                count: latterkey_s[l].count,
              });
            }
          }
          // console.log(
          //   `${name}:${latterkey[l]._id.latterkey},${latterkey[l].count}`
          // );
        }
      }
      senArr.push({
        level: level.title,
        formerA: formerkeyArray_s,
        latterA: latterkeyArray_s,
        YformerA: YformerkeyArray_s,
        YlatterA: YlatterkeyArray_s,
      });
    });
    senArr.map((item) => {
      // console.log(item.latterA);
      item.formerkey = countKeyword(item.formerA);
      item.latterkey = countKeyword(item.latterA);
      item.Yformerkey = countKeyword(item.YformerA);
      item.Ylatterkey = countKeyword(item.YlatterA);
    });

    setKeywordDataS(senArr);
    //歸納實際句層標記數量
    let levelArray = [];
    let level2Array = [];
    // console.log(level);
    // console.log(allLevel);

    allLevel.map((item) => {
      // console.log(item._id);

      levelArray.push({
        sentencelevel: item._id,
        Count: item.count,
      });
    });

    levelList.map((item) => {
      let n = 0;
      let n2 = 0;
      for (let i = 0; i < level.length; i++) {
        // console.log(level[i]._id);
        if (level[i]._id == item.value) {
          n = level[i].count;
        }
      }
      for (let i = 0; i < allLevel.length; i++) {
        // console.log(level[i]._id);
        if (allLevel[i]._id == item.value) {
          n2 = allLevel[i].count;
        }
      }
      level2Array.push({
        sentencelevel: item.value,
        Count: n,
        allCount: n2,
      });
    });
    // console.log(level2Array);
    setLevelData(levelArray);
    setLevel2Data(level2Array);
    // console.log(levelData);
  };
  //關聯詞處理N
  const countKeywordN = (arr) => {
    //累計重複詞的次數
    let newArr = arr.reduce((acc, curr) => {
      acc[curr.key] = (acc[curr.key] || 0) + curr.count;
      return acc;
    }, {});
    //遞減排序
    let sortArr = [];
    for (var obj in newArr) {
      sortArr.push({ key: obj, count: newArr[obj] });
    }
    sortArr.sort(function (a, b) {
      return b.count - a.count;
    });
    return sortArr;
  };
  //關聯詞處理
  const countKeyword = (arr) => {
    //累計重複詞的次數
    let newArr = arr.reduce((acc, curr) => {
      acc[curr.key] = (acc[curr.key] || 0) + curr.count;
      return acc;
    }, {});
    //遞減排序
    let sortArr = [];
    for (var obj in newArr) {
      sortArr.push([obj, newArr[obj]]);
    }
    sortArr.sort(function (a, b) {
      return b[1] - a[1];
    });

    //將array改成string呈現
    let newStr = [];
    let str;
    for (let i = 0; i < sortArr.length; i++) {
      str = sortArr[i].join('');
      // console.log(str);
      newStr.push(str);
    }
    //紀錄個數
    if (newStr.length > 0) {
      let num = newStr.length;
      newStr = num + '種：' + newStr.join('、');
    } else {
      newStr = newStr.join('、');
    }

    // console.log(newStr);
    return newStr;
  };

  //統計實際複句標記量
  const countRelation = (fuju) => {
    let totalnum = 0;
    let tableArray = [];

    for (let i = 0; i < fuju.length; i++) {
      totalnum = totalnum + fuju[i].count;
      tableArray.push({
        key: i + 1,
        relation: fuju[i]._id,
        Count: fuju[i].count,
      });
    }
    setTableData(tableArray);
    setTotal(totalnum);
  };
  //歸納複句關係標記量(按分類)
  const countRelationTree = (fuju) => {
    let relation = [];
    let num = 0;

    let saveName1;
    let rootName;
    let saveName2;
    let leafName;
    relationTree.map((rel) => {
      // console.log(rel.title);
      let rootNum = 0;

      for (let i = 0; i < rel.children.length; i++) {
        // console.log(rel.children[i].title);
        let leafNum = 0;

        for (let k = 0; k < rel.children[i].children.length; k++) {
          // console.log(rel.children[i].children[k].title);

          let cnt = 0;
          for (let q = 0; q < fuju.length; q++) {
            // console.log(`${data[i]._id}:${data[i].count}`);
            let name = rel.children[i].children[k].title;
            if (fuju[q]._id.includes(name)) {
              // console.log(`${fujuCount[q]._id}:${fujuCount[q].count}`);
              cnt += fuju[q].count;
              // console.log(`${name}:${fujuCount[q]._id}-${cnt}`);
            }
          }
          num++;
          leafNum += cnt;
          rootNum += cnt;

          if (rel.title == saveName1) {
            rootName = '';
          } else {
            rootName = rel.title;
            saveName1 = rel.title;
          }
          if (rel.children[i].title == saveName2) {
            leafName = '';
          } else {
            leafName = rel.children[i].title;
            saveName2 = rel.children[i].title;
          }
          relation.push({
            key: num,
            root: rootName,
            leaf: leafName,
            relation: rel.children[i].children[k].title,
            Count: cnt,
          });
        }
        relationPie2.push([rel.children[i].title, leafNum]);
      }
      relationPie1.push([rel.title, rootNum]);
    });

    setTable2Data(relation);

    setrelation1(relationPie1);
    setrelation2(relationPie2);
  };

  //統計實際複句標記量(已授權)
  const countStrictly = () => {
    countRelation(data);
    countRelationTree(data);
  };
  //統計實際複句標記量(全部)
  const countSimply = () => {
    countRelation(dataAll);
    countRelationTree(dataAll);
  };
  const [bySource, setBySource] = useState(true);
  const [byType, setByType] = useState(false);
  const [byStyle, setByStyle] = useState(false);
  const [byBoth, setByBoth] = useState(false);
  //文章列表(總計)
  const countBySource = () => {
    setBySource(true);
    setByType(false);
    setByStyle(false);
    setByBoth(false);
  };
  //文類
  const countByType = () => {
    setByType(true);
    setBySource(false);
    setByStyle(false);
    setByBoth(false);
  };
  //文體
  const countByStyle = () => {
    setByStyle(true);
    setBySource(false);
    setByType(false);
    setByBoth(false);
  };
  //文體&文類
  const countByBoth = () => {
    setByBoth(true);
    setByStyle(false);
    setBySource(false);
    setByType(false);
  };

  const [bylevel, setBylevel] = useState(false);
  const [byRelation, setByRelation] = useState(false);
  const [byCount, setByCount] = useState(true);
  //統計關聯詞(總計)
  const countByCount = () => {
    setByCount(true);
    setBylevel(false);
    setByRelation(false);
  };
  //統計關聯詞(複句關係)
  const countByRelation = () => {
    setByRelation(true);
    setByCount(false);
    setBylevel(false);
  };
  //統計關聯詞(句層)
  const countByLevel = () => {
    setBylevel(true);
    setByCount(false);
    setByRelation(false);
  };

  const [show, setShow] = useState(false);
  //選擇顯示哪一張圓餅圖
  const handleChange = (e) => {
    if (e.target.value === 'root') {
      setPieData(relation1);
      setPieOptions(op1);
      setShow(true);
    }
    if (e.target.value === 'leaf') {
      setPieData(relation2);
      setPieOptions(op2);
      setShow(true);
    }
    if (e.target.value === 'source') {
      setPieData(type);
      setPieOptions(op3);
      setShow(true);
    }
  };

  return (
    <div style={{ padding: '1rem' }}>
      {!auth && (
        <div>
          <h6>必須先登入。</h6>
          <button
            onClick={handleTakeToLogin}
            className='btn btn-primary btn-sm'
          >
            登入
          </button>
        </div>
      )}
      <h3 className='display-4 fs-3 fw-bold'>語料統計</h3>
      <hr />
      {auth && (
        <div>
          <Tabs size='large' tabPosition='left'>
            <Tabs.TabPane tab='關係統計' key='item-2'>
              <div className='row align-items-start'>
                <div className='col'>
                  <div
                    className='blog__controller btn-group me-2 position-absolute end-0 d-flex flex-row'
                    role='group'
                  >
                    <>
                      <Button
                        variant='btn btn-outline-secondary'
                        onClick={countSimply}
                      >
                        全部
                      </Button>
                      <Button
                        variant='btn btn-outline-secondary'
                        onClick={countStrictly}
                      >
                        已授權
                      </Button>
                    </>
                  </div>
                  <h5>實際標記數</h5>
                  <hr />

                  <h6>
                    共{tableData.length}種標記，{total}筆標記數，按遞增排序。
                  </h6>

                  <Table
                    loading={loading}
                    style={{ width: '400px' }}
                    bordered
                    dataSource={tableData}
                    columns={fujuColumns}
                    size='small'
                    pagination={false}
                    sticky
                    // title={() => <div></div>}
                  />
                </div>
                <div className='col'>
                  <h5>複句關係統計</h5>
                  <hr />

                  <h6>
                    共{relationTree.length}大類， 14次類，
                    {relationList.length}組關係，按複句關係排序。
                  </h6>
                  <Table
                    loading={loading}
                    style={{ width: '700px' }}
                    bordered
                    dataSource={table2Data}
                    columns={TreeColumns}
                    size='small'
                    pagination={false}
                    sticky
                    // title={() => <div></div>}
                  />
                </div>
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane tab='文章統計' key='item-3'>
              <div className='row align-items-start'>
                <div className='col'>
                  <div
                    className='blog__controller btn-group me-2 position-absolute end-0 d-flex flex-row'
                    role='group'
                  >
                    <>
                      <Button
                        variant='btn btn-outline-secondary'
                        onClick={countBySource}
                      >
                        文章標記列表
                      </Button>
                      <Button
                        variant='btn btn-outline-secondary'
                        onClick={countByType}
                      >
                        文類統計
                      </Button>
                      <Button
                        variant='btn btn-outline-secondary'
                        onClick={countByStyle}
                      >
                        文體統計
                      </Button>
                      {/* <Button
                        variant='btn btn-outline-secondary'
                        onClick={countByBoth}
                      >
                        文體與文類配對統計
                      </Button> */}
                    </>
                  </div>
                  {bySource && (
                    <div>
                      <h5>文章標記列表({SourceData.length}篇)</h5>
                      <hr />
                      <Table
                        loading={loading}
                        bordered
                        dataSource={SourceData}
                        columns={sourceColumns}
                        size='large'
                        pagination={false}
                        sticky
                      />
                    </div>
                  )}
                  {byType && (
                    <div>
                      <div className='col'>
                        <h5>文類標記量({typeList.length}類)</h5>
                        <hr />

                        <Table
                          style={{ width: '1000px' }}
                          loading={loading}
                          bordered
                          dataSource={typeData}
                          columns={typeColumns}
                          size='small'
                          pagination={false}
                          sticky
                          summary={(pageData) => {
                            let total1 = 0;
                            let total2 = 0;
                            let total3 = 0;

                            pageData.forEach(({ number, CountAll, Count }) => {
                              total1 += number;
                              total2 += CountAll;
                              total3 += Count;
                            });
                            return (
                              <>
                                <Table.Summary.Row>
                                  <Table.Summary.Cell>
                                    <Text type='warning'>Total</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <Text type='warning'>{total1}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <Text type='warning'>{total2}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <Text type='warning'>{total3}</Text>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                              </>
                            );
                          }}
                        />
                      </div>
                      <br />

                      <div className='col'>
                        <h5>文類標記量({classList.length}類)</h5>
                        <hr />

                        <Table
                          style={{ width: '1000px' }}
                          loading={loading}
                          bordered
                          dataSource={classData}
                          columns={typeColumns}
                          size='small'
                          pagination={false}
                          sticky
                          summary={(pageData) => {
                            let total1 = 0;
                            let total2 = 0;
                            let total3 = 0;

                            pageData.forEach(({ number, CountAll, Count }) => {
                              total1 += number;
                              total2 += CountAll;
                              total3 += Count;
                            });
                            return (
                              <>
                                <Table.Summary.Row>
                                  <Table.Summary.Cell>
                                    <Text type='warning'>Total</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <Text type='warning'>{total1}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <Text type='warning'>{total2}</Text>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell>
                                    <Text type='warning'>{total3}</Text>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {byStyle && (
                    <div>
                      <h5>文體標記量({styleList.length}類)</h5>
                      <hr />

                      <Table
                        style={{ width: '1000px' }}
                        loading={loading}
                        bordered
                        dataSource={styleData}
                        columns={styleColumns}
                        size='small'
                        pagination={false}
                        sticky
                        summary={(pageData) => {
                          let total1 = 0;
                          let total2 = 0;
                          let total3 = 0;

                          pageData.forEach(({ number, CountAll, Count }) => {
                            total1 += number;
                            total2 += CountAll;
                            total3 += Count;
                          });
                          return (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell>
                                  <Text type='warning'>Total</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                  <Text type='warning'>{total1}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                  <Text type='warning'>{total2}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                  <Text type='warning'>{total3}</Text>
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab='句層統計' key='item-1'>
              <div className='row align-items-start'>
                <div className='col'>
                  <h5>實際句層標記(遞增排序)</h5>
                  <hr />

                  <Table
                    loading={loading}
                    sticky
                    style={{ width: '400px' }}
                    bordered
                    dataSource={levelData}
                    columns={sentenceColumns}
                    size='small'
                    pagination={false}
                    // title={() => <div></div>}
                  />
                </div>
                <div className='col'>
                  <h5>句層標記</h5>
                  <hr />

                  <Table
                    loading={loading}
                    sticky
                    style={{ width: '400px' }}
                    bordered
                    dataSource={level2Data}
                    columns={levelColumns}
                    size='small'
                    pagination={false}
                    // title={() => <div></div>}
                  />
                </div>
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane tab='關聯詞統計' key='item-4'>
              <div className='row align-items-start'>
                <div className='col'>
                  <div
                    className='blog__controller btn-group me-2 position-absolute end-0 d-flex flex-row'
                    role='group'
                  >
                    <>
                      <Button
                        variant='btn btn-outline-secondary'
                        onClick={countByCount}
                      >
                        總計
                      </Button>
                      <Button
                        variant='btn btn-outline-secondary'
                        onClick={countByRelation}
                      >
                        依複句關係分類
                      </Button>
                      <Button
                        variant='btn btn-outline-secondary'
                        onClick={countByLevel}
                      >
                        依句層分類
                      </Button>
                    </>
                  </div>
                  <h5>關聯詞統計</h5>
                  <hr />
                  {byCount && (
                    <>
                      <h6>
                        統計前後關聯詞的出現次數，並依次數遞減排序，
                        <b>
                          前語段關聯詞共
                          {totalF_K}種，{totalF}個；後語段關聯詞共{totalL_K}種，
                          {totalL}個。
                        </b>
                      </h6>
                    </>
                  )}
                  {byRelation && (
                    <>
                      <h6>
                        按複句關係分類，統計前後關聯詞、語氣副詞的出現次數，在各類中前頭以XX種表示關聯詞種類數量，並依次數遞減排序。
                      </h6>
                    </>
                  )}
                  {bylevel && (
                    <>
                      <h6>
                        按句層分類，統計前後關聯詞、語氣副詞的出現次數，在各類中前頭以XX種表示關聯詞種類數量，並依次數遞減排序。
                      </h6>
                    </>
                  )}

                  <br />
                  {byCount && (
                    <Table
                      loading={loading}
                      // style={{ width: "1000px" }}
                      bordered
                      dataSource={keywordDataA}
                      columns={keywordColumnsA}
                      size='large'
                      pagination={false}
                      sticky

                      // title={() => <div></div>}
                    />
                  )}
                  {byRelation && (
                    <Table
                      loading={loading}
                      // style={{ width: "1000px" }}
                      bordered
                      dataSource={keywordDataR}
                      columns={keywordColumns}
                      size='large'
                      pagination={false}
                      sticky

                      // title={() => <div></div>}
                    />
                  )}
                  {bylevel && (
                    <Table
                      loading={loading}
                      // style={{ width: "1000px" }}
                      bordered
                      dataSource={keywordDataS}
                      columns={keywordColumnsL}
                      size='large'
                      pagination={false}
                      sticky

                      // title={() => <div></div>}
                    />
                  )}
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab='統計圖' key='item-5'>
              <div className='row align-items-start'>
                <div className='col'>
                  <div
                    className='blog__controller btn-group me-2 position-absolute end-0 d-flex flex-row'
                    role='group'
                  >
                    <>
                      <Button
                        value='root'
                        onClick={handleChange}
                        variant='btn btn-outline-secondary'
                      >
                        複句大類統計
                      </Button>
                      <Button
                        value='leaf'
                        onClick={handleChange}
                        variant='btn btn-outline-secondary'
                      >
                        複句次類統計
                      </Button>
                      <Button
                        value='source'
                        onClick={handleChange}
                        variant='btn btn-outline-secondary'
                      >
                        文章類型統計
                      </Button>
                    </>
                  </div>
                  <h5>統計圖</h5>
                  <hr />
                  {show && (
                    <div>
                      <Chart
                        chartType='PieChart'
                        data={pieData}
                        options={pieOptions}
                        width={'100%'}
                        height={'800px'}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Tabs.TabPane>
          </Tabs>
          <ScrollToTopButton />
        </div>
      )}
    </div>
  );
};

export default AnalysisComponent;
