import React, { useEffect, useState } from 'react';
import FileService from '../services/file.service';
// import { useNavigate } from "react-router-dom";
import { Button, Modal, Spinner } from 'react-bootstrap';
// import "bootstrap/dist/css/bootstrap.min.css";
import SourceService from '../services/source.service';
import * as XLSX from 'xlsx';

const UploadWindowN = ({ currentUser, _NO, show, onHide }) => {
  // console.log(`uploadWindow: ${_NO}`);
  // let { currentUser, setCurrentUser } = props;
  let [message, setMessage] = useState('');
  let [loading, setLoading] = useState(false);
  let [name, setName] = useState('');
  // const [wrongSource, setWrongSource] = useState('');
  //範例檔
  const titleMapping = {
    // 最新標記: 'relation',
    語義關係: 'relation',
    句法結構: 'syntax',
    句層標記: 'sentencelevel',
    前語段: 'former',
    後語段: 'latter',
    前語段關聯詞: 'formerkey',
    後語段關聯詞: 'latterkey',
    出處: 'source',
    標記者: 'tagger',
    檢查者: 'reviser',
    備註: 'note',
  };
  const [file, setFile] = useState(null); // 用于存储选择的文件

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
    setName(selectedFile.name);
  };
  const [error, setError] = useState(null); // 用于存储错误信息

  const handleFileUpload = async () => {
    const queryResult = await SourceService.getAuthorTitle(_NO);
    if (!file) {
      // setError('請先選擇一個檔案');
      window.alert('請先選擇一個檔案');
      return;
    }
    setLoading(true);
    setError(null);
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // 读取第一个工作表
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        // 使用 header: 1 提取標題行和數據行
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headerRow = jsonData[0]; // 假設第一行是標題行
        const dataRows = jsonData.slice(1); // 其餘行是數據

        const wrongHeader = [];
        const requiredColumns = [
          '語義關係',
          '句法結構',
          '句層標記',
          '前語段',
          '後語段',
          '前語段關聯詞',
          '後語段關聯詞',
          '出處',
          '標記者',
          '檢查者',
        ];

        // 先檢查標題列
        for (const col of requiredColumns) {
          if (!headerRow.includes(col)) {
            wrongHeader.push(col);
          }
        }

        if (wrongHeader.length > 0) {
          throw new Error(`缺少標題欄位: ${wrongHeader.join(', ')}`);
        }

        // 將數據行轉換回物件格式
        const formattedData = dataRows.map((row) => {
          return row.reduce((acc, value, index) => {
            acc[headerRow[index]] = value;
            return acc;
          }, {});
        });
        //檢核資料
        const errorMessages = [];
        formattedData.forEach((row, index) => {
          const formerData = row['前語段'] ? row['前語段'].trim() : '';
          const relationData = row['語義關係'] ? row['語義關係'].trim() : '';
          const syntaxData = row['句法結構'] ? row['句法結構'].trim() : '';
          const sentenceLayer = row['句層標記'] ? row['句層標記'].trim() : '';
          const sourceName = row['出處'] ? row['出處'].trim() : '';
          const taggerData = row['標記者'] ? row['標記者'].trim() : '';
          // 條件1: 前語段為空白:但有出處則跳錯
          if (formerData === '') {
            if (sourceName !== '') {
              errorMessages.push(`第 ${index + 2} 列 - 前語段未填，出處有值。`);
            }
          } else {
            if (sourceName === '') {
              errorMessages.push(`第 ${index + 2} 列 - 出處未填，前語段有值。`);
            }
          }
          if (formerData !== '' && sourceName !== '') {
            // 條件2: 出處有多餘空格、欄位不一致
            // if (row['出處'] !== sourceName) {
            //   errorMessages.push(
            //     `第 ${index + 2} 列 - 出處"${row['出處']}"前或後有多餘空格`
            //   );
            // }
            if (sourceName !== queryResult.data) {
              errorMessages.push(
                `第 ${
                  index + 2
                } 列 - 出處欄位不一致。列出處為:"${sourceName}"，應為:"${
                  queryResult.data
                }"`
              );
            }
            if (relationData === '' && syntaxData === '') {
              errorMessages.push(
                `第 ${index + 2} 列 - 語義和句法標記欄位都未填。`
              );
            }

            // 條件3: 句層標記欄位未填
            if (sentenceLayer === '') {
              errorMessages.push(`第 ${index + 2} 列 - 句層標記欄位未填。`);
            }
            // else {
            //   // 檢查是否前後有多餘空格
            //   if (row['句層標記'] !== sentenceLayer) {
            //     errorMessages.push(
            //       `第 ${index + 2} 列 - 句層標記"${
            //         row['句層標記']
            //       }" 前或後有多餘空格。`
            //     );
            //   }
            // }
            // 條件4: 句層D,DD,CZ 句法必填
            if (
              (sentenceLayer === 'D' ||
                sentenceLayer === 'DD' ||
                sentenceLayer === 'CZ') &&
              syntaxData === ''
            ) {
              errorMessages.push(
                `第 ${index + 2} 列 - 句層為D,DD,CZ，句法結構未填。`
              );
            }
            // 條件5: 句層F,DF,J 語義必填
            if (
              (sentenceLayer === 'F' ||
                sentenceLayer === 'DF' ||
                sentenceLayer === 'J') &&
              relationData === ''
            ) {
              errorMessages.push(
                `第 ${index + 2} 列 - 句層為J,F,DF，語義關係未填。`
              );
            }
            // 條件6: 句層NP要改成CZ
            if (sentenceLayer.includes('NP')) {
              errorMessages.push(`第 ${index + 2} 列 - 句層NP應改為CZ。`);
            }
            // if (relationData && row['語義關係'] !== relationData) {
            //   errorMessages.push(
            //     `第 ${index + 2} 列 - 語義關係"${
            //       row['語義關係']
            //     }"前或後有多餘空格`
            //   );
            // }
            // if (syntaxData && row['句法結構'] !== syntaxData) {
            //   errorMessages.push(
            //     `第 ${index + 2} 列 - 句法結構"${
            //       row['句法結構']
            //     }"前或後有多餘空格`
            //   );
            // }
            // 條件7: 語義否則要改成假轉
            if (relationData.includes('否則')) {
              errorMessages.push(`第 ${index + 2} 列 - 否則應改為假轉。`);
            }
            // 條件8: 語義否則要改成假轉
            if (syntaxData.includes('子句')) {
              errorMessages.push(`第 ${index + 2} 列 - 子句應改為句內。`);
            }
            // 條件8: 句法不能填在語義欄位
            if (
              relationData.includes('主謂') ||
              relationData.includes('兼語') ||
              relationData.includes('述賓') ||
              relationData.includes('狀中') ||
              relationData.includes('定中')
            ) {
              errorMessages.push(
                `第 ${index + 2} 列 - ${relationData}不應填入語義關係。`
              );
            }
            if (taggerData === '') {
              errorMessages.push(`第 ${index + 2} 列 - 標記者欄位未填。`);
            }
          }
        });
        // 如果有錯誤，拋出異常
        if (errorMessages.length > 0) {
          const errorMessageHtml = errorMessages.join('<br />');
          setError(errorMessageHtml);
          // setLoading(false);
          // return;
          throw new Error('上傳資料有誤，請核對錯誤清單。');
        }

        //除去空白行=只取出處和前語段有值的列
        const selectedColumns = formattedData
          .filter((row, index) => {
            // 去除前後空白的 '前語段' 和 '出處'
            const formerData = row['前語段'] ? row['前語段'].trim() : '';
            const sourceData = row['出處'] ? row['出處'].trim() : '';

            // 檢查 '前語段' 和 '出處' 是否都非空白
            return formerData !== '' && sourceData !== '';
          })
          .map((row) => ({
            // 選擇特定的列並去除前後空白
            語義關係: row['語義關係'] ? row['語義關係'].trim() : '',
            句法結構: row['句法結構'] ? row['句法結構'].trim() : '',
            句層標記: row['句層標記'] ? row['句層標記'].trim() : '',
            前語段: row['前語段'],
            後語段: row['後語段'],
            前語段關聯詞: row['前語段關聯詞'] ? row['前語段關聯詞'].trim() : '',
            後語段關聯詞: row['後語段關聯詞'] ? row['後語段關聯詞'].trim() : '',
            出處: row['出處'] ? row['出處'].trim() : '',
            標記者: row['標記者'] ? row['標記者'].trim() : '',
            檢查者: row['檢查者'] ? row['檢查者'].trim() : '',
            備註: row['備註'] ? row['備註'].trim() : '',
          }));
        // console.log(selectedColumns);
        // 转换列标题
        const transformedData = selectedColumns.map((row) => {
          return Object.keys(row).reduce((acc, key) => {
            const newKey = titleMapping[key] || key; // 使用映射后的英文标题，如果没有匹配则保留原标题
            acc[newKey] = row[key]; // 保持数据值不变
            return acc;
          }, {});
        });
        // 上传数据
        FileService.post(currentUser, transformedData)
          .then((data) => {
            setLoading(false);
            window.alert('上傳成功!' + data);
            setName('');
            window.location.reload();
          })
          .catch((error) => {
            console.log(error.response);
            setLoading(false);
            setMessage(error.response.data);
            window.alert('上傳失敗!' + message);
          });
      } catch (error) {
        // 捕获错误并停止执行
        window.alert(error.message);
        setLoading(false);
        return;
        // window.location.reload(); // 重新加载页面
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      {...{ show, onHide }}
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>上傳檔案</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <br />
          <div class='input-group'>
            <input
              type='file'
              class='form-control'
              id='inputGroupFile04'
              aria-describedby='inputGroupFileAddon04'
              aria-label='Upload'
              name={name}
              onChange={handleFileChange}
              accept='.xlsx, .xls'
            />{' '}
            {!loading && (
              <button
                class='btn btn-outline-secondary'
                type='button'
                id='inputGroupFileAddon04'
                onClick={handleFileUpload}
              >
                上傳
              </button>
            )}
            {loading && (
              <Button variant='dark' disabled>
                <Spinner
                  as='span'
                  variant='light'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                  animation='border'
                />
                上傳中
              </Button>
            )}
          </div>
          <br />
        </div>
        <span>
          <h6>
            ※標題:句法結構、語義關係、句層標記、前語段、後語段、前語段關聯詞、後語段關聯詞、出處、標記者、檢查者
          </h6>
          <h6>※前語段、出處、標記者必填，句法結構、語義關係擇一必填。</h6>
          <h6>※如要覆蓋原本標記資料，請先刪除資料再進行上傳。</h6>
          <h6>※如資料有誤無法上傳，請先根據錯誤清單排除錯誤原因後再上傳。</h6>
          {error && (
            <>
              <hr />
              <h5>
                <b>錯誤清單</b>
              </h5>
              <h6 dangerouslySetInnerHTML={{ __html: error }} />
            </>
          )}
        </span>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='outline-secondary' onClick={onHide}>
          關閉
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default UploadWindowN;
