import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import SourceService from '../services/source.service';
import { Table, Input, Form, Modal as AntdModal, Typography } from 'antd';
const { TextArea } = Input;
const { Text, Title, Paragraph } = Typography;

const ContextWindow = ({ currentUser, _NO, authorTitle, show, onHide }) => {
  let [title, setTitle] = useState('');
  let [number, setNumber] = useState('');

  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState('');
  const [editedText, setEditedText] = useState('');
  if (_NO === 'all') {
    SourceService.getNumber(authorTitle)
      .then((res) => {
        // console.log(res.data);
        setTitle(authorTitle);
        setNumber(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // setLoading(false);
      });
  } else {
    SourceService.getAuthorTitle(_NO)
      .then((res) => {
        // console.log(res.data);
        setTitle(res.data);
        setNumber(_NO);

        // disableBeforeUnload();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // setLoading(false);
      });
  }
  if (
    currentUser &&
    (currentUser.user.role === 'staff' || currentUser.user.role === 'admin')
  ) {
    SourceService.getContext(number)
      .then((res) => {
        console.log(res.data);
        setText(res.data);
        // disableBeforeUnload();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // setLoading(false);
      });
  } else {
    window.alert('沒有權限');
  }

  // console.log(context);
  const handleEdit = (event) => {
    setEditedText(text);
    setIsEditing(true);
  };
  const handleInputChange = (event) => {
    console.log(`更改後:${event.target.value}`);
    setEditedText(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveText();
  };
  const saveText = () => {
    // console.log(_NO, text);
    SourceService.updateContext(number, editedText)
      .then((res) => {
        console.log(`保存後:${res.data}`);
        window.alert('更新成功');
        setText(editedText);
        setIsEditing(false);
      })
      .catch((err) => {
        window.alert('更新失敗');
      });
  };
  const handleKeyDown = (event) => {
    if (event.keyCode === 9) {
      // 按下Tab键的keyCode为9
      event.preventDefault(); // 阻止默认行为
      const { selectionStart, selectionEnd, value } = event.target;
      const newValue =
        value.substring(0, selectionStart) +
        '\t' +
        value.substring(selectionEnd);
      setEditedText(newValue);
    }
  };
  return (
    <div>
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        {...{ show, onHide }}
      >
        <Modal.Header>
          <Modal.Title id='contained-modal-title-vcenter'>
            {number}-{title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isEditing && (
            <form onSubmit={handleSubmit}>
              <textarea
                style={{ height: '800px', width: '700px', fontSize: 20 }}
                value={editedText}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder='輸入原文...'
              />
              <Modal.Footer>
                <Button variant='secondary' type='submit'>
                  保存
                </Button>
                <Button
                  variant='outline-secondary'
                  onClick={() => setIsEditing(false)}
                >
                  取消
                </Button>
              </Modal.Footer>
            </form>
          )}
          {!isEditing && (
            <div>
              <Paragraph style={{ fontSize: 20, whiteSpace: 'pre-wrap' }}>
                {text}
              </Paragraph>
            </div>
          )}
        </Modal.Body>

        {!isEditing && (
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={handleEdit}
              // disabled={currentUser.user.role === 'staff'}
            >
              修改
            </Button>
            <Button variant='outline-secondary' onClick={onHide}>
              關閉
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default ContextWindow;
