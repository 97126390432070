import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Table } from 'antd';

const KeywordWindow = ({
  currentUser,
  currentKeyword,
  currentData,
  show,
  onHide,
}) => {
  const keywordColumns = [
    {
      title: '複句關係',
      key: 'relation',
      dataIndex: 'relation',
      width: '6vw',
    },

    {
      title: '次數',
      key: 'count',
      dataIndex: 'count',
      width: '6vw',
    },
    {
      title: '百分比',
      key: 'percent',
      dataIndex: 'percent',
      width: '6vw',
    },
  ];
  // console.log(currentData);
  //計算各關係出現次數
  let arr = [];
  let res = [];
  let total = 0;

  currentData.map((item) => {
    arr.push(item.relation);
    total++;
  });
  // console.log(arr);
  let counts = {};

  arr.forEach((el) => {
    counts[el] = counts[el] ? counts[el] + 1 : 1;
  });
  // console.log(counts);

  let countsSorted = Object.entries(counts).sort(([_, a], [__, b]) => b - a);
  // console.log(countsSorted);
  for (var i in countsSorted) {
    res.push({
      relation: countsSorted[i][0],
      count: countsSorted[i][1],
      percent:
        Math.round(
          ((countsSorted[i][1] / total) * 100 + Number.EPSILON) * 100
        ) /
          100 +
        '%',
    });
  }
  return (
    <div>
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        scrollable
        {...{ show, onHide }}
      >
        <Modal.Header>
          <Modal.Title id='contained-modal-title-vcenter'>
            「{currentKeyword}」於複句各小類分布句數和百分比
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table
            bordered
            dataSource={res}
            columns={keywordColumns}
            size='small'
            pagination={false}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant='outline-secondary' onClick={onHide}>
            關閉
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default KeywordWindow;
