import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import UploadWindow from "./uploadwindow-componentN";
import ContextWindow from "./context-component";

import SourceService from "../services/source.service";
import { Modal } from "antd";
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const ControllerButton = ({ data, currentUser }) => {
  const [uploadShow, setUploadShow] = useState(false);
  const [contextShow, setContextShow] = useState(false);

  //取得網址中文章編號
  let url = window.location.href;
  let arr = [];
  let _NO;
  if (url.indexOf("eachTable/") != -1) {
    arr = url.split("eachTable/");
    // console.log(arr[1]);
    _NO = arr[1];
  }
  //設定下載檔名加上日期
  let d = new Date();
  let dformat = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  // console.log("getCurrentDate : ", dformat);
  // let fileName = '_' + dformat + '.csv';
  let fileName = _NO + "_" + dformat + ".xlsx";

  //設定下載的表頭欄位(舊的)
  let fileHeaders;
  if (currentUser.user.role === "staff" || currentUser.user.role === "admin") {
    fileHeaders = [
      { label: "語義關係", key: "relation" },
      // { label: '句法關係', key: 'syntax' },
      { label: "句層標記", key: "sentencelevel" },
      { label: "前語段", key: "former" },
      { label: "後語段", key: "latter" },
      { label: "前語段關聯詞", key: "formerkey" },
      { label: "後語段關聯詞", key: "latterkey" },
      { label: "出處", key: "source" },
      { label: "標記者", key: "tagger" },
      { label: "檢查者", key: "reviser" },
      { label: "備註", key: "note" },
    ];
  } else if (currentUser.user.role === "guest") {
    fileHeaders = [
      { label: "最新標記", key: "relation" },
      { label: "句層標記", key: "sentencelevel" },
      { label: "前語段", key: "former" },
      { label: "後語段", key: "latter" },
      { label: "前語段關聯詞", key: "formerkey" },
      { label: "後語段關聯詞", key: "latterkey" },
      { label: "出處", key: "source" },
    ];
  }

  //取得下載的資料
  const [fileData, setFileData] = useState([]);
  const handleDownload = async () => {
    // console.log("~~~~~~");
    // console.log(data);
    setFileData(data);
    //取得下載的資料(新的)
    const selectedFields = data.map((row) => ({
      // 最新標記: row.relation,
      句層標記: row.sentencelevel,
      句法結構: row.syntax,
      語義關係: row.relation,
      前語段: row.former,
      後語段: row.latter,
      前語段關聯詞: row.formerkey,
      後語段關聯詞: row.latterkey,
      出處: row.source,
      標記者: row.tagger,
      檢查者: row.reviser,
      備註: row.note,
    }));
    // 将数据转换为工作表
    const worksheet = XLSX.utils.json_to_sheet(selectedFields);

    // 创建工作簿并将工作表添加到其中
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // 生成 Excel 文件的二进制数据
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // 将生成的 Excel 文件保存到用户的本地存储
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, fileName);
  };

  //刪除該文本資料

  const deleteData = () => {
    Modal.confirm({
      title: "確定刪除這份資料?",
      okText: "確認",
      cancelText: "取消",
      onOk: () => {
        SourceService.delete(arr[1])
          .then((res) => {
            alert(res.data);
            // Modal.success({
            //   title: "刪除成功",
            //   content: `${res.data}`,
            //   okText: "確認",
            // });
            window.location.reload();
          })
          .catch((err) => {
            // console.log(err);
            alert(`未刪除資料${err}`);
            // Modal.error({
            //   title: "刪除失敗",
            //   content: `未刪除資料${err}`,
            //   okText: "確認",
            // });
          });
      },
    });
  };
  return (
    <div>
      {url.indexOf("all") <= 0 && (
        <div
          className="blog__controller btn-group me-2 position-absolute end-0 d-flex flex-row"
          role="group"
        >
          <Button
            variant="btn btn-outline-secondary"
            onClick={() => setContextShow(true)}
          >
            原文閱覽
          </Button>
          <>
            <Button
              variant="btn btn-secondary"
              onClick={deleteData}
              // disabled={currentUser.user.role === 'staff'}
            >
              刪除標記檔
            </Button>
            <Button
              variant="btn btn-secondary"
              onClick={() => setUploadShow(true)}
              // disabled={currentUser.user.role === "staff"}
            >
              新增標記檔
            </Button>
            {/* 下載資料(舊的) */}
            {/* <CSVLink
              headers={fileHeaders}
              data={fileData}
              filename={_NO + fileName}
              target='_blank'
              className='btn btn-secondary'
              onClick={handleData}
            >
              下載標記檔
            </CSVLink> */}
            <Button className="btn btn-secondary" onClick={handleDownload}>
              下載標記檔
            </Button>
          </>
        </div>
      )}
      {url.indexOf("all") >= 0 &&
        (currentUser.user.role === "staff" ||
          currentUser.user.role === "admin") && (
          <div
            className="blog__controller btn-group me-2 position-absolute end-0 d-flex flex-row"
            role="group"
          >
            <Button className="btn btn-secondary" onClick={handleDownload}>
              下載資料
            </Button>
            {/* 下載資料(舊的) */}
            {/* <CSVLink
              headers={fileHeaders}
              data={fileData}
              filename={'results' + fileName}
              target='_blank'
              className='btn btn-secondary'
            >
              下載資料
            </CSVLink> */}
          </div>
        )}
      {uploadShow && (
        <UploadWindow /** 上傳視窗 */
          currentUser={currentUser}
          _NO={_NO}
          show={uploadShow}
          onHide={() => setUploadShow(false)}
        />
      )}
      {contextShow && (
        <ContextWindow /** 原文視窗 */
          currentUser={currentUser}
          _NO={_NO}
          show={contextShow}
          onHide={() => setContextShow(false)}
        />
      )}
    </div>
  );
};
export default ControllerButton;
