import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthService from '../services/auth.service';
import { TabTitle } from './changeTitle';
import { Modal } from 'antd';

const RegisterComponent = () => {
  useEffect(() => {
    TabTitle('註冊');
  }, []);

  const history = useNavigate();
  let [username, setUsername] = useState('');
  let [email, setEmail] = useState('');
  let [password, setPassword] = useState('');
  let [role, setRole] = useState('stranger');
  let [message, setMessage] = useState('');
  let [background, setBackground] = useState('');
  let [purpose, setPurpose] = useState('');
  let [selectOther, setSelectOther] = useState(false);
  let [otherPurpose, setOtherPurpose] = useState(false);

  const handleChangeUsername = (e) => {
    setUsername(e.target.value);
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangeBackground = (e) => {
    setBackground(e.target.value);
  };
  const handleChangePurpose = (e) => {
    if (e.target.value === '其他') {
      setSelectOther(true);
    } else {
      setSelectOther(false);
    }
    setPurpose(e.target.value);
  };
  const handleChangeOtherPurpose = (e) => {
    setOtherPurpose(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };
  // const handleChnageRole = (e) => {
  //   setRole(e.target.value);

  // };
  const handleRegister = () => {
    if (purpose !== '其他') {
      otherPurpose = '';
    }
    AuthService.register(
      username,
      email,
      password,
      role,
      background,
      purpose,
      otherPurpose
    )
      .then(() => {
        Modal.success({
          title: '送出成功',
          content: `感謝您的申請！審核結果將寄信通知您。`,
          okText: '確認',
        });
        history('/');
      })
      .catch((error) => {
        console.log(error.response);
        setMessage(error.response.data);
      });
  };

  return (
    <div className='p-5 col-md-5 container'>
      <div>
        {message && (
          <div className='alert alert-danger' style={{ fontSize: '20px' }}>
            {message}
          </div>
        )}
        <div>
          <h5>
            本站採用人工審核作業，請先完成註冊申請，待內部審核通過後便會啟用您的帳號。
          </h5>
        </div>
        <div>
          <label htmlFor='username' style={{ fontSize: '25px' }}>
            姓名
          </label>
          <input
            onChange={handleChangeUsername}
            type='text'
            className='form-control'
            name='username'
          />
        </div>
        <br />
        <div className='form-group'>
          <label htmlFor='background' style={{ fontSize: '25px' }}>
            學校/單位
          </label>
          <input
            onChange={handleChangeBackground}
            type='text'
            className='form-control'
            name='background'
          />
        </div>
        <br />
        <div className='form-group'>
          <label htmlFor='use' style={{ fontSize: '25px' }}>
            申請用途
          </label>
          <select
            class='form-select'
            aria-label='請選擇用途'
            onChange={handleChangePurpose}
          >
            <option value=''>請選擇用途</option>
            <option value='研究'>研究</option>
            <option value='工作'>工作</option>
            <option value='其他'>其他</option>
          </select>
          <br />
          {selectOther && (
            <input
              placeholder='請說明其他用途...'
              onChange={handleChangeOtherPurpose}
              type='text'
              className='form-control'
              name='otherUse'
            />
          )}
        </div>
        <br />
        <div className='form-group'>
          <label htmlFor='email' style={{ fontSize: '25px' }}>
            信箱
          </label>
          <input
            onChange={handleChangeEmail}
            type='text'
            className='form-control'
            name='email'
          />
        </div>
        <br />
        <div className='form-group'>
          <label htmlFor='password' style={{ fontSize: '25px' }}>
            密碼
          </label>
          <input
            onChange={handleChangePassword}
            type='password'
            className='form-control'
            name='password'
          />
        </div>
        <br />
        {/* <div className="form-group">
          <label htmlFor="password">身分</label>
          <input
            onChange={handleChnageRole}
            type="text"
            className="form-control"
            name="role"
          />
        </div> */}
        <button onClick={handleRegister} className='btn btn-primary'>
          <span>註冊</span>
        </button>
      </div>
    </div>
  );
};

export default RegisterComponent;
