import React from "react";
import { useNavigate, Link } from "react-router-dom";

const FooterComponent = () => {
  return (
    <div>
      <div className="b-example-divider"></div>
      <div className="container">
        <footer className="pt-3 mt-4 ">
          <ul className="nav justify-content-center border-bottom pb-3 mb-3">
            <li className="nav-item">
              <Link className="nav-link px-2 text-muted" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link px-2 text-muted" to="/about">
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link px-2 text-muted" to="/source">
                DataSource
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link px-2 text-muted" to="/eachTable/all">
                Corpus
              </Link>
            </li>
          </ul>

          <p className="text-center text-muted">
            &copy;2022 科技部計畫「漢語複句體系及特性研究」(A study on the
            system and characteristics of Chinese compound and complex
            sentences)
          </p>
        </footer>
      </div>
    </div>
  );
};

export default FooterComponent;
