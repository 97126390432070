import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { TabTitle } from './changeTitle';

const HomeComponent = (props) => {
  let { currentUser, setCurrentUser } = props;

  const history = useNavigate();
  const goRegister = () => {
    // alert("目前不提供註冊。");
    history('/register');
  };
  const goContent = () => {
    if (!currentUser) {
      alert('請先登入。');
    } else {
      history('/source');
    }
  };
  const goSearch = () => {
    if (!currentUser) {
      alert('請先登入。');
    } else {
      history('/eachTable/all');
    }
  };
  const goStatistics = () => {
    if (!currentUser) {
      alert('請先登入。');
    } else {
      history('/analysis');
    }
    // alert("統計功能尚未開放。");
  };
  const goAbout = () => {
    history('/about');
  };
  useEffect(() => {
    TabTitle('首頁');
  }, []);
  return (
    <main>
      <div className='container theme-showcase'>
        {/* <div className="jumbotron"> */}
        <div className='container-fluid py-5'>
          <h1 className='display-5 fs-2 fw-bold'>現代漢語複句語料庫</h1>
          <p className='col-md-8 fs-5' style={{ width: '100%' }}>
            「現代漢語複句語料庫」由國立臺灣大學張麗麗國科會計畫團隊所建置。
            <br />
            本網站提供複句標記語料，選用現代文本作為語料來源，包含散文、報導、演講、小説等不同文類，並為每篇文章的每組複句加上複句關係、句層、關聯詞等標記。
            <br />
            本語料庫提供複句關係、句層、關聯詞之檢索功能，檢得結果以符合直覺的表格欄位呈現，每列資料包含「關係」、「句層」、「前分句」、「後分句」、「前關聯詞」、「後關聯詞」及「作者篇名」共七個欄位。
          </p>
          <button
            className='btn btn-outline-secondary btn-sm'
            type='button'
            onClick={goAbout}
          >
            詳細介紹
          </button>
          {/* </div> */}
        </div>

        <div className='row align-items-md-stretch'>
          <div className='col-md-3'>
            <div className='h-100 p-5 text-white bg-dark rounded-3'>
              <h2 style={{ color: 'white' }}>帳號註冊</h2>
              <p>目前語料庫帳號採人工審核流程，待帳號啟用後才能查閱語料庫。</p>
              <button
                className='btn btn-outline-light'
                type='button'
                onClick={goRegister}
              >
                立即註冊
              </button>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='h-100 p-5 bg-warning border rounded-3'>
              <h2>語料目錄</h2>
              <p>
                表列本語料庫的語料來源，包含文類、文體、作者篇名以及出處共四個欄位。
              </p>
              <button
                className='btn btn-outline-dark'
                type='button'
                onClick={goContent}
              >
                立即點閱
              </button>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='h-100 p-5 bg-info border rounded-3'>
              <h2>語料檢索</h2>
              <p>
                提供多條件檢索，包含關係檢索、句層檢索、關聯詞檢索、任意字串檢索，以及文類及文體檢索。
              </p>
              <button
                className='btn btn-outline-dark'
                type='button'
                onClick={goSearch}
              >
                立即使用
              </button>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='h-100 p-5 bg-secondary border rounded-3'>
              <h2>語料統計</h2>
              <p>
                提供本語料庫的統計資料，包含複句關係、關聯詞、句層、文類及文體這四方面的分析圖。
              </p>
              <button
                className='btn btn-outline-light'
                type='button'
                onClick={goStatistics}
              >
                立即使用
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default HomeComponent;
