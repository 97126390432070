import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL + '/api/user';
// const API_URL = "http://localhost:8080/api/user";

class AuthService {
  login(email, password) {
    return axios.post(API_URL + '/login', { email, password });
  }
  logout() {
    localStorage.removeItem('user');
  }

  register(username, email, password, role, background, purpose, otherPurpose) {
    return axios.post(API_URL + '/register', {
      username,
      email,
      password,
      role,
      background,
      purpose,
      otherPurpose,
    });
  }
  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  checkJwt() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.post(API_URL + '/checkJwt', {
      headers: {
        Authorization: token,
      },
    });
  }
}

export default new AuthService();
