import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SourceService from '../services/source.service';
import FuguService from '../services/fugu.service';
import ScrollToTopButton from './ScrollToTopButton-component';

import {
  typeList,
  copyrightList,
  statusList,
  nameList,
} from './searchOption-component';
import 'antd/dist/antd.css';
import { Table, Switch, Typography, Modal } from 'antd';
import { TabTitle } from './changeTitle';
const { Text } = Typography;
const WorkReportComponent = (props) => {
  let { currentUser, setCurrentUser } = props;
  const history = useNavigate();
  const handleTakeToLogin = () => {
    history('/login');
  };
  const handle401 = (err) => {
    if (err.response.status === 401) {
      const modal = Modal.confirm({
        title: '權限已失效',
        content: `權限已失效，請重新登入。`,
        okText: '登入',
        cancelText: '取消',
        onOk: () => {
          modal.destroy();
          history('/login');
        },
        onCancel: () => {
          history('/');
        },
      });
    }
  };
  let [totalData, setTotalData] = useState([]);
  let [tableData, setTableData] = useState([]);
  let [tableData1, setTableData1] = useState([]);

  let [loading, setLoading] = useState(true);
  // let [sourceData, setSourceData] = useState(null);
  let tableArray = [];
  let briefTable = [];
  let totalTable = [];
  let [auth, setAuth] = useState(false);

  useEffect(() => {
    TabTitle('助理工作報表');
    if (
      currentUser.user.role === 'staff' ||
      currentUser.user.role === 'admin'
    ) {
      findData();
      setAuth(true);
    } else {
      window.alert('沒有權限');
    }
  }, []);

  // 設定table欄位
  const totalColumn = [
    Table.EXPAND_COLUMN,

    {
      title: '文類',
      key: 'type',
      dataIndex: 'type',
      width: '6vw',
    },
    {
      title: '篇數',
      key: 'numbers',
      dataIndex: 'numbers',
      width: '5vw',
    },
    {
      title: '已授權',
      key: 'copyright',
      dataIndex: 'copyright',
      width: '5vw',
    },
    {
      title: '已完成',
      key: 'finished',
      dataIndex: 'finished',
      width: '10vw',
      children: [
        {
          title: '標記',
          key: 'marked',
          dataIndex: 'marked',
          width: '5vw',
        },

        {
          title: '檢查',
          key: 'checked',
          dataIndex: 'checked',
          width: '5vw',
        },
        {
          title: '上傳',
          key: 'uploaded',
          dataIndex: 'uploaded',
          width: '5vw',
        },
      ],
    },
    {
      title: '未完成',
      key: 'uncompleted',
      dataIndex: 'uncompleted',
      width: '10vw',
      children: [
        {
          title: '標記',
          key: 'unmarked',
          dataIndex: 'unmarked',
          width: '5vw',
        },
        {
          title: '檢查',
          key: 'unchecked',
          dataIndex: 'unchecked',
          width: '5vw',
        },
        {
          title: '上傳',
          key: 'not_uploaded',
          dataIndex: 'not_uploaded',
          width: '5vw',
        },
      ],
    },

    {
      title: '待分配',
      key: 'unassigned',
      dataIndex: 'unassigned',
      width: '10vw',
      children: [
        {
          title: '標記',
          key: 'unmark',
          dataIndex: 'unmark',
          width: '5vw',
        },
        {
          title: '檢查',
          key: 'uncheck',
          dataIndex: 'uncheck',
          width: '5vw',
        },
      ],
    },
  ];
  const briefColumns = [
    {
      title: '姓名',
      key: 'name',
      dataIndex: 'name',
      width: '6vw',
    },

    {
      title: '標記中',
      key: 'marking',
      dataIndex: 'marking',
      width: '10vw',
    },

    {
      title: '檢查中',
      key: 'checking',
      dataIndex: 'checking',
      width: '10vw',
    },
    {
      title: '未上傳',
      key: 'notUploaded',
      dataIndex: 'notUploaded',
      width: '10vw',
    },
  ];
  const columns = [
    {
      title: '姓名',
      key: 'name',
      dataIndex: 'name',
      width: '6vw',
    },
    {
      title: '校對中',
      key: 'proofreading',
      dataIndex: 'proofreading',
      width: '6vw',
    },
    {
      title: '已校對',
      key: 'proofreaded',
      dataIndex: 'proofreaded',
      width: '6vw',
    },
    {
      title: '標記中',
      key: 'marking',
      dataIndex: 'marking',
      width: '10vw',
    },
    {
      title: '已標記',
      key: 'marked',
      dataIndex: 'marked',
      width: '10vw',
    },

    {
      title: '檢查中',
      key: 'checking',
      dataIndex: 'checking',
      width: '10vw',
    },
    {
      title: '已檢查',
      key: 'checked',
      dataIndex: 'checked',
      width: '10vw',
    },
  ];

  const findData = async () => {
    let data;
    await SourceService.get()
      .then((res) => {
        console.log(res.data);
        data = res.data;
        // setSourceData(res.data);
      })
      .catch((err) => {
        handle401(err);
        // console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
    let fujudata;
    await FuguService.countSource()
      .then((data) => {
        // console.log(data);
        fujudata = data;
      })
      .catch((err) => {
        handle401(err);
        // console.log(err);
      })
      .finally(() => {
        // countParent();
        setLoading(false);
      });
    //統計整體進度(各類文本完成狀態)
    let uploaded_No = [];

    for (let i = 0; i < typeList.length; i++) {
      let typeCount = 0;
      let copyrightCount = 0;
      let markedCount = 0;
      let checkedCount = 0;
      let unmarkCount = 0;
      let uncheckCount = 0;
      let unmarkArray = [];
      let uncheckArray = [];

      let uploadedCount = 0;
      let XuploadedCount = 0;
      let Xuploaded_No = [];
      const fujudataIds = new Set(fujudata.map((item) => item._id));
      for (let k = 0; k < data.length; k++) {
        const item = data[k];

        // 检查 item 是否为 undefined 或 null
        if (!item) {
          continue; // 如果 item 为 undefined 或 null，跳过这次循环
        }

        if (item.type !== typeList[i].text) {
          continue;
        }

        typeCount++;

        if (item.copyright === '已授權') {
          copyrightCount++;
        }

        const status = item.status;
        const isMarked = [
          '已標記',
          '已檢查',
          '檢查中',
          '公開',
          '不公開',
        ].includes(status);

        if (isMarked) {
          markedCount++;

          if (fujudataIds.has(item.author_title)) {
            uploadedCount++;
            uploaded_No.push(item._NO);
          } else if (['已檢查', '公開', '不公開'].includes(status)) {
            Xuploaded_No.push(item._NO);
            XuploadedCount++;
          }
        }

        if (['已檢查', '公開', '不公開'].includes(status)) {
          checkedCount++;
        }

        if (item.tagger === '待分配') {
          unmarkCount++;
          unmarkArray.push(item._NO);
        }

        if (item.reviser === '待分配') {
          uncheckCount++;
          uncheckArray.push(item._NO);
        }
      }
      // console.log(uploaded_No);

      // console.log(Xuploaded_No);

      let des = '';
      if (Xuploaded_No.length) {
        des = des + `未上傳： ${Xuploaded_No.join('、')} `;
      }
      if (unmarkCount) {
        des = des + `；待分配標記：${unmarkArray.join('、')} `;
      }
      if (uncheckCount) {
        des = des + `；待分配檢查： ${uncheckArray.join('、')}`;
      }

      // if (not_uploadedName.length) {
      //   des = des + `；未上傳： ${not_uploadedName.join("、")}`;
      // }
      // console.log(
      //   `${typeList[i].text}: 表定${typeCount}/已上傳:${uploadedCount} `
      // );

      totalTable.push({
        key: i,
        type: typeList[i].text,
        numbers: typeCount,
        copyright: copyrightCount,
        marked: markedCount,
        uploaded: uploadedCount,
        checked: checkedCount,
        unmarked: typeCount - markedCount,
        unchecked: markedCount - checkedCount,
        not_uploaded: XuploadedCount,
        unmark: unmarkCount,
        uncheck: uncheckCount,
        description: des,
      });
    }
    setTotalData(totalTable);

    //統計各助理工作進度
    for (let i = 1; i < nameList.length; i++) {
      if (nameList[i].workreport !== 'Y') {
        continue;
      }
      let markedArray = [];
      let markingArray = [];
      let markingArrayD = [];
      let checkedArray = [];
      let checkingArray = [];
      let checkingArrayD = [];
      let notUploadedArrayD = [];
      let proofreadedArray = [];
      let proofreadingArray = [];
      let markedCount = 0;
      let markingCount = 0;
      let checkedCount = 0;
      let checkingCount = 0;
      let proofreadedCount = 0;
      let proofreadingCount = 0;

      data.forEach((item) => {
        // 防止 item 为 undefined 或 null
        if (!item) return;

        const { tagger, reviser, proofreader, status, _NO, author_title } =
          item;

        // 处理 tagger 匹配
        if (tagger && tagger.match(nameList[i].text)) {
          if (['標記中', '已校對'].includes(status)) {
            markingArray.push(_NO);
            markingArrayD.push(`${_NO} ${author_title}`);
            markingCount++;
          }
          if (['已檢查', '公開', '不公開'].includes(status)) {
            const exists = fujudata.some(
              (fuju) => fuju._id === author_title && fuju.count > 0
            );
            if (!exists) {
              notUploadedArrayD.push(`${_NO} ${author_title}`);
            }
          }
          if (['已標記', '已檢查', '檢查中'].includes(status)) {
            markedArray.push(_NO);
            markedCount++;
          }
        }

        // 处理 reviser 匹配
        if (reviser && reviser.match(nameList[i].text)) {
          if (['檢查中', '已標記'].includes(status)) {
            checkingArray.push(_NO);
            checkingArrayD.push(`${_NO} ${author_title}`);
            checkingCount++;
          }
          if (status === '已檢查') {
            checkedArray.push(_NO);
            checkedCount++;
          }
        }

        // 处理 proofreader 匹配
        if (proofreader && proofreader.match(nameList[i].text)) {
          if (status === '校對中') {
            proofreadingArray.push(_NO);
            proofreadingCount++;
          }
          if (status === '已校對') {
            proofreadedArray.push(_NO);
            proofreadedCount++;
          }
        }
      });

      let checkedString = lineSplit(checkedArray);
      let markedString = lineSplit(markedArray);
      let markingString = lineSplit(markingArray);
      let markingStringD = showDataWithHtml(markingArrayD);
      let checkingString = lineSplit(checkingArray);
      let checkingStringD = showDataWithHtml(checkingArrayD);
      let notUploadedStringD = showDataWithHtml(notUploadedArrayD);
      let proofreadedString = lineSplit(proofreadedArray);
      let proofreadingString = lineSplit(proofreadingArray);

      // 总表
      tableArray.push({
        key: i,
        name: nameList[i].text,
        proofreading: proofreadingCount,
        proofreaded: proofreadedCount,
        marking: markingCount,
        marked: markedCount,
        checking: checkingCount,
        checked: checkedCount,
        children: [
          {
            key: i + 10 * i,
            name: '',
            proofreading: proofreadingString,
            proofreaded: proofreadedString,
            marking: markingString,
            marked: markedString,
            checking: checkingString,
            checked: checkedString,
          },
        ],
      });

      // 简表
      briefTable.push({
        key: i,
        name: nameList[i].text,
        marking: markingStringD,
        checking: checkingStringD,
        notUploaded: notUploadedStringD,
      });
    }
    setTableData(tableArray);
    setTableData1(briefTable);
  };
  //每個字換行
  const showDataWithHtml = (array) => {
    return (
      <div>
        {array.map((line) => (
          <h6>{line}</h6>
        ))}
      </div>
    );
  };
  //每十字換行
  const lineSplit = (line) => {
    let newLine = line
      .join('、')
      .replace(/[^\x00-\xff]/g, '$&\x01')
      .replace(/.{10}\x01?/g, '$&\n')
      .replace(/\x01/g, '');
    return newLine;
  };

  return (
    <div style={{ padding: '1rem' }}>
      {!currentUser && (
        <div>
          <h6>必須先登入。</h6>
          <button
            onClick={handleTakeToLogin}
            className='btn btn-primary btn-sm'
          >
            登入
          </button>
        </div>
      )}
      {!auth && (
        <div>
          <h6>該帳號沒有權限使用此功能。</h6>
        </div>
      )}
      {auth && (
        <div>
          <h3 className='display-4 fs-3 fw-bold'>助理工作報表</h3>
          <hr />
          <div>
            <h4>整體進度</h4>
            <div>
              <Table
                sticky
                bordered
                dataSource={totalData}
                columns={totalColumn}
                pagination={false}
                style={{ fontSize: 20 }}
                size='small'
                expandable={{
                  expandedRowRender: (record) => (
                    <p
                      style={{
                        margin: 0,
                      }}
                    >
                      {record.description}
                    </p>
                  ),
                }}
                summary={(pageData) => {
                  let totalNumber = 0;
                  let totalCopyright = 0;
                  let totalMarked = 0;
                  let totalChecked = 0;
                  let totalUploaded = 0;
                  let totalUnmarked = 0;
                  let totalUnchecked = 0;
                  let totalUnmark = 0;
                  let totalUncheck = 0;
                  let totalNotUploaded = 0;

                  pageData.forEach(
                    ({
                      numbers,
                      copyright,
                      marked,
                      checked,
                      uploaded,
                      unmarked,
                      unchecked,
                      not_uploaded,
                      unmark,
                      uncheck,
                    }) => {
                      totalNumber += numbers;
                      totalCopyright += copyright;
                      totalMarked += marked;
                      totalChecked += checked;
                      totalUploaded += uploaded;
                      totalUnmarked += unmarked;
                      totalUnchecked += unchecked;
                      totalUnmark += unmark;
                      totalUncheck += uncheck;
                      totalNotUploaded += not_uploaded;
                    }
                  );
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell>Total</Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text type='success'>{totalNumber}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text type='success'>{totalCopyright}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text type='success'>{totalMarked}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text type='success'>{totalChecked}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text type='success'>{totalUploaded}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text type='success'>{totalUnmarked}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text type='success'>{totalUnchecked}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text type='success'>{totalNotUploaded}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text type='success'>{totalUnmark}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text type='success'>{totalUncheck}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </div>
          </div>
          <div>
            <br />
            <h4>工作進度檔(簡檔)</h4>
            <div>
              <Table
                bordered
                dataSource={tableData1}
                columns={briefColumns}
                pagination={false}
                style={{ fontSize: 20 }}
                size='small'
                sticky
              />
            </div>
          </div>
          <div>
            <br />
            <h4>工作進度檔(總檔)</h4>
            <div>
              <Table
                bordered
                dataSource={tableData}
                columns={columns}
                pagination={false}
                style={{ fontSize: 20 }}
                sticky
              />
            </div>
            <ScrollToTopButton />
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkReportComponent;
