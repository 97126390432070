import React, { useEffect, useState } from 'react';
import { TabTitle } from './changeTitle';
import {
  relationTree,
  relationList,
  typeList,
  levelList,
  styleList,
} from './searchOption-component';
const AboutComponent = () => {
  useEffect(() => {
    TabTitle('計畫介紹');
    handleData();
  }, []);
  const projectData = [
    { name: '連詞與關聯副詞比較研究（兩年期）', period: '101/08-103/07' },
    { name: '漢語複句與單句轉換研究（兩年期）', period: '103/08-105/07' },
    { name: '語氣副詞與複句（三年期）', period: '105/08-108/07' },
    { name: '漢語讓轉關係的通與變（三年期）', period: '108/08-111/07' },
    { name: '漢語複句體系及特性研究（兩年期）', period: '111/08-113/07' },
  ];
  let [relationData, setRelationData] = useState([]);
  const handleData = () => {
    let data = [];

    let saveName1;
    let rootName;
    let saveName2;
    let leafName;
    let class1Count = 0;
    let class2Count = 0;
    let class3Count = 0;

    relationTree.map((rel) => {
      class1Count += 1;
      // console.log(rel.title);
      for (let i = 0; i < rel.children.length; i++) {
        // console.log(rel.children[i].title);
        class2Count += 1;
        let str = '';
        for (let k = 0; k < rel.children[i].children.length; k++) {
          if (str !== '') {
            str += '、';
          }
          str += rel.children[i].children[k].title;

          // console.log(rel.children[i].children[k].title);
          class3Count += 1;
        }
        if (rel.title == saveName1) {
          rootName = '';
        } else {
          rootName = rel.title;
          saveName1 = rel.title;
        }
        if (rel.children[i].title == saveName2) {
          leafName = '';
        } else {
          leafName = rel.children[i].title;
          saveName2 = rel.children[i].title;
        }
        data.push({
          class1: rootName == '' ? '' : toRomanNumeral(class1Count) + rootName,
          class2: leafName == '' ? '' : class2Count + leafName,
          class3: str,
        });
      }
    });
    console.log(data);
    setRelationData(data);
    // console.log(relationData);
  };
  function toRomanNumeral(number) {
    const romanNumeralTable = {
      1000: 'M',
      900: 'CM',
      500: 'D',
      400: 'CD',
      100: 'C',
      90: 'XC',
      50: 'L',
      40: 'XL',
      10: 'X',
      9: 'IX',
      5: 'V',
      4: 'IV',
      1: 'I',
    };

    let result = '';

    for (const key of Object.keys(romanNumeralTable).sort((a, b) => b - a)) {
      while (number >= key) {
        result += romanNumeralTable[key];
        number -= key;
      }
    }

    return result;
  }
  // const relationData = [
  //   { class1: 'I並列類', class2: '1並列', class3: '並列、累加、伴隨、交替' },
  //   { class2: '2選擇', class3: '選擇' },
  //   { class2: '3遞進', class3: '遞進' },
  //   { class1: 'II承接類', class2: '4承接', class3: '承接、事承、持續' },
  //   { class2: '5時間', class3: '時間' },
  //   {
  //     class1: 'III解說類',
  //     class2: '6解說',
  //     class3: '解說、改說、例證、引述、注解、申論、異同',
  //   },
  //   {
  //     class2: '7評論',
  //     class3: '評論、主題、背景、比擬、比較',
  //   },
  //   {
  //     class2: '8總分',
  //     class3: '總分、分總',
  //   },
  //   {
  //     class2: '9總結',
  //     class3: '總結',
  //   },
  //   {
  //     class1: 'IV因果類',
  //     class2: '10因果',
  //     class3: '因果、推論、致使、釋因',
  //   },
  //   {
  //     class2: '11目的',
  //     class3: '為目、以目',
  //   },
  //   {
  //     class1: 'V條件類',
  //     class2: '12假設',
  //     class3: '假設、否則',
  //   },
  //   {
  //     class2: '13條件',
  //     class3: '條件、排除、倚變',
  //   },
  //   {
  //     class1: 'VI讓轉類',
  //     class2: '14讓步',
  //     class3: '實讓、虛讓、總讓、忍讓',
  //   },
  //   {
  //     class2: '15轉折',
  //     class3: '轉折、對比、取捨、遞轉',
  //   },
  //   {
  //     class1: 'VII單句類',
  //     class2: '16單句',
  //     class3:
  //       '主謂、述賓、兼語、修飾、子句、處置、憑藉、地方、連繫、呼告、對象、立場、來源、方向、範圍、受益、伴者',
  //   },
  // ];
  return (
    <div>
      <br />
      <br />
      <div className='jumbotron'>
        <div className='container mb-5'>
          <h3 className='display-5 fs-3 fw-bold'>計畫介紹</h3>
          <hr />
          <p className='fs-5'>
            &emsp;&emsp;
            本語料庫是為研究漢語複句而規劃的，以標點符號為分句界線，標記由標點符號連接之前後句段的關係，具有下列幾項特點：
          </p>
          <ul className='fs-5 no-bullets'>
            <li>一、不以複句為限，也標記由標點符號連接之單句和句組。</li>
            <li> 二、複句無論有無關聯詞（含連詞及關聯副詞），都加以標記。</li>
            <li>
              三、針對每組語料，列出前語段和後語段，並標記下列三方面訊息：句層、關係、關聯詞和語氣副詞。
            </li>
            <li>
              四、提出一套含6大類、15基本類以及44小類之複句分類架構，連同純單句關係，共含7大類、16基本類以及61小類之分類架構。
            </li>
          </ul>
          <p className='fs-5'>
            &emsp;&emsp;
            有鑑於漢語單句和複句界線並不明確，本語料庫雖以複句為首要標記對象，也一併容納由標點符號隔開的語言單位。漢語標點符號可連接三種類型的語言單位，可區分句組、複句、單句這三種層次，其中句組係由複句及更大結構所構成。在本語料庫中，這三種層次的語言構成均加以標記。
          </p>
          <p className='fs-5'>
            &emsp;&emsp;
            漢語複句雖可由關聯詞標記，但也很常見不帶關聯詞的情形，甚至有的複句類型從不帶關聯詞。過去研究往往側重帶標記的複句，而忽略數量龐大的無標複句。本語料庫特提供全面的複句語料，針對每篇選文，窮盡地列出其中所有複句並標記其間關係。本語料庫可根據關係檢索，故針對同一關係，可同時檢得有標和無標例句，將有助於全面瞭解各類複句使用情況。
          </p>
          <p className='fs-5'>
            &emsp;&emsp;
            針對每組複句，本語料庫分別列出前語段和後語段，也分別列出前後語段所含關聯詞（含連詞和關聯副詞），並提供關聯詞檢索功能。漢語關聯詞往往可連接多種關係，像是「就」可連接承接、因果、假設、條件等關係，「也」可連接並列、虛讓、總讓等關係，而在實際使用中，其功能要更為廣泛，本語料庫當有助於快速全面地掌握各關聯詞的實際使用狀況。漢語關聯詞數量龐大，但實際範圍和數量一直無法清楚探知，本語料庫亦有助於掌握此現象。為充分達成此目的，本語料庫也一併標記語氣副詞，因後者也經常具有調節複句關係之作用。
          </p>
          <p className='fs-5'>
            &emsp;&emsp;
            為達成標記句間關係之目的，本語料庫特提出一套兼含句組、複句和單句關係之分類架構，見下表。此架構乃計畫在十餘年複句標記工作中不斷修正調整的成果，也包含不帶關聯詞的複句類型，像是解說複句、評論複句等等。複句關係，共區分6大類、15基本類以及44小類。純單句關係，則含17小類。在實際標記中，句組和複句共用同套分類架構，不另區分；單句除純單句關係，也可能具複句關係，但是複句不會表單句關係。關於本語料庫關係分類架構更詳細的介紹，請見
            <a href='/directions'>標記說明</a>。
          </p>

          <table
            style={{ width: '90%' }}
            className='table table-bordered table-hover table-sm fs-5'
          >
            <thead className='thead-dark'>
              <tr>
                <th style={{ width: '20%', textAlign: 'center' }}>大類</th>
                <th style={{ width: '20%', textAlign: 'center' }}>基本類</th>
                <th style={{ width: '60%', textAlign: 'center' }}>小類</th>
              </tr>
            </thead>
            <tbody>
              {relationData.map((item) => (
                <tr>
                  <td style={{ textAlign: 'center' }}>{item.class1}</td>
                  <td style={{ textAlign: 'center' }}>{item.class2}</td>
                  <td>{item.class3}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className='container mb-5'>
        <h3 className='display-5 fs-3 fw-bold '>致謝</h3>
        <hr />
        <p className='fs-5'>
          本語料庫共收錄X篇文章。感謝各篇文章作者同意無償授權本語料庫使用，也感謝聯合知識庫同意授權使用所收錄文章，並特此感謝《臺大校友》編輯部以及國科會人文及社會科研究發展處《多聲部島語》編輯部協助取得作者授權。
        </p>
        <p className='fs-5'>
          本語料庫獲國科會計畫長期支持，以下計畫均有部分經費投入本語料庫的建置，特此聲明致謝。
        </p>
        <table
          style={{ width: '90%' }}
          className='table table-bordered table-hover table-sm fs-5'
        >
          <thead className='thead-dark'>
            <tr>
              <th style={{ width: '60%', textAlign: 'center' }}>計畫名稱</th>
              <th style={{ width: '40%', textAlign: 'center' }}>計畫期間</th>
            </tr>
          </thead>
          <tbody>
            {projectData.map((item) => (
              <tr key={item.name}>
                <td style={{ textAlign: 'center' }}>{item.name}</td>
                <td style={{ textAlign: 'center' }}>{item.period}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className='container mb-5'>
        <h3 className='display-5 fs-3 fw-bold'>計畫成員</h3>
        <hr />
        <p className='fs-5'>
          <b>計畫主持人</b>
        </p>
        <p className='fs-5'>張麗麗</p>
        <p className='fs-5'>國立臺灣大學中國文學系教授</p>
        <p className='fs-5'>
          <b>國科會計畫歷任兼任助理</b>
        </p>
        <p className='fs-5'>李育憬、謝宗廷</p>
        <p className='fs-5'>羅喻方、王品程、周世平</p>
        <p className='fs-5'>賴芊孜、豐田由佳、楊淯琇、葉祖璇</p>
      </div>
      <div className='container mb-5'>
        <h3 className='display-5 fs-3 fw-bold'>聯絡方式</h3>
        <hr />
        <p className='fs-5'>複句小組</p>
        <p className='fs-5'>信箱：lilichang2016@gmail.com</p>
      </div>
    </div>
  );
};

export default AboutComponent;
