import axios from 'axios';
// const API_URL = "http://localhost:8080/api/source";
const API_URL = process.env.REACT_APP_BACKEND_URL + '/api/source';

class SourceService {
  //查找所有出處
  get() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.get(API_URL + '/', {
      headers: {
        Authorization: token,
      },
    });
  }

  //查找公開出處
  getPublic() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.get(API_URL + '/onPublic', {
      headers: {
        Authorization: token,
      },
    });
  }

  // 查找對應文章編號的作者篇名
  getAuthorTitle(_NO) {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.get(API_URL + '/authorTitle/' + _NO, {
      headers: {
        Authorization: token,
      },
    });
  }
  // 查找對應作者篇名的文章編號
  getNumber(authorTitle) {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.get(API_URL + '/number/' + authorTitle, {
      headers: {
        Authorization: token,
      },
    });
  }
  // 查找原文檔by文章編號
  getContext(_NO) {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.get(API_URL + '/context/' + _NO, {
      headers: {
        Authorization: token,
      },
    });
  }

  //更新原文檔by文章編號
  updateContext(_N0, context) {
    // console.log(`record.id ${record._id}`);
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.put(
      API_URL + '/update/' + _N0,
      {
        context,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  // 查找複句標記檔by文章編號
  getSentence(_NO) {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.get(API_URL + '/fujuData/' + _NO, {
      headers: {
        Authorization: token,
      },
    });
  }

  //更新複句標記檔by文章編號
  put(record) {
    // console.log(`record.id ${record._id}`);
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.put(
      API_URL + '/' + record._id,
      {
        record,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  //刪除複句標記檔by文章編號
  delete(_NO) {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.delete(API_URL + '/' + _NO, {
      headers: {
        Authorization: token,
      },
    });
  }
}

export default new SourceService();
