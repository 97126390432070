import axios from 'axios';
// const API_URL = "http://localhost:8080/api/fugu";
const API_URL = process.env.REACT_APP_BACKEND_URL + '/api/fugu';
class FuguService {
  //新增複句
  post(
    currentUser,
    record
    // relation,
    // sentencelevel,
    // former,
    // latter,
    // formerkey,
    // latterkey,
    // source,
    // tagger,
    // reviser,
    // order,
  ) {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.post(
      API_URL,
      {
        currentUser,
        record,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  //顯示複句
  get() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.get(API_URL + '/', {
      headers: {
        Authorization: token,
      },
    });
  }
  //顯示公開複句
  getPublic() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.get(API_URL + '/onPublic', {
      headers: {
        Authorization: token,
      },
    });
  }
  //查找複句關係標記數(已授權)
  countRelation() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }

    return axios
      .get(API_URL + '/countRelation', {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        return res.data;
      });
  }
  //查找複句關係標記數(全)
  countRelationAll() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }

    return axios
      .get(API_URL + '/countRelationAll', {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        return res.data;
      });
  }
  //查找前關聯詞總數
  countFormerKeyA() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }

    return axios
      .get(API_URL + '/countFormerKeyA', {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        return res.data;
      });
  }
  //查找後關聯詞總數
  countLatterKeyA() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }

    return axios
      .get(API_URL + '/countLatterKeyA', {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        return res.data;
      });
  }
  //查找前關聯詞標記數(複句關係)
  countFormerKey() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }

    return axios
      .get(API_URL + '/countFormerKey', {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        return res.data;
      });
  }
  //查找前關聯詞標記數(句層)
  countFormerKey_S() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }

    return axios
      .get(API_URL + '/countFormerKey-s', {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        return res.data;
      });
  }
  //查找後關聯詞標記數
  countLatterKey() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }

    return axios
      .get(API_URL + '/countLatterKey', {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        return res.data;
      });
  }
  //查找後關聯詞標記數(句層)
  countLatterKey_S() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }

    return axios
      .get(API_URL + '/countLatterKey-s', {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        return res.data;
      });
  }
  //查找句層標記數
  countLevel() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }

    return axios
      .get(API_URL + '/countLevel', {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        return res.data;
      });
  }
  countAllLevel() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }

    return axios
      .get(API_URL + '/countAllLevel', {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        return res.data;
      });
  }
  //查找文本標記數
  countSource() {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }

    return axios
      .get(API_URL + '/countSource', {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log(res.data);
        return res.data;
      });
  }
  //查找複句
  search(
    relation,
    syntax,
    sentencelevel,
    type,
    style,
    tagger,
    reviser,
    formerkey,
    latterkey,
    former,
    latter,
    role
  ) {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    let searchCondition = {
      relation: relation,
      syntax: syntax,
      sentencelevel: sentencelevel,
      type: type,
      style: style,
      tagger: tagger,
      reviser: reviser,
      formerkey: formerkey,
      latterkey: latterkey,
      former: former,
      latter: latter,
      role: role,
    };
    // console.log(`searchCondition: ${JSON.stringify(searchCondition)}`);
    return axios.post(
      API_URL + '/search',
      { searchCondition },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    // return axios.get(API_URL + "/" + relation, {
    //   headers: {
    //     Authorization: token,
    //   },
    // });
  }
  //更新複句
  put(record) {
    // console.log(`record.id ${record._id}`);
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.put(
      API_URL + '/' + record._id,
      {
        record,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  //刪除複句
  delete(_id) {
    let token;
    if (localStorage.getItem('user')) {
      token = JSON.parse(localStorage.getItem('user')).token;
    } else {
      token = '';
    }
    return axios.delete(API_URL + '/' + _id, {
      headers: {
        Authorization: token,
      },
    });
  }
  // post(files) {
  //   let token;
  //   if (localStorage.getItem("user")) {
  //     token = JSON.parse(localStorage.getItem("user")).token;
  //   } else {
  //     token = "";
  //   }
  //   return axios.post(API_URL + "/upload", {
  //     headers: {
  //       Authorization: token,
  //     },
  //   });
  // }
}

export default new FuguService();
